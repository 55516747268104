
import React from "react";

export const PlaceholderImage3 = ({ className }) => {
  return (
    <svg
      className={`placeholder-image-3 ${className}`}
      fill="none"
      viewBox="0 0 563 356"
      height="auto"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill="#EFEFEF" height="356" rx="24" width="563" />
      <path
        className="path"
        d="M324.5 215.333V140.667C324.5 134.8 319.7 130 313.833 130H239.167C233.3 130 228.5 134.8 228.5 140.667V215.333C228.5 221.2 233.3 226 239.167 226H313.833C319.7 226 324.5 221.2 324.5 215.333ZM257.833 186L271.167 202.053L289.833 178L313.833 210H239.167L257.833 186Z"
        fill="#9CB0C9"
      />
    </svg>
  );
};