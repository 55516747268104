import { userLoginRequest, userLoginSuccess, userLoginFail, userLogout } from '../../features/user/userSlice'

import axios from 'axios'

export const login = (email, password, navigate) => async (dispatch, getState) => {
    try {
        dispatch(userLoginRequest());

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const { data } = await instance.post(
            '/api/user/login/',
            { 'username': email, 'password': password },

        )

        dispatch(userLoginSuccess(data));
        localStorage.setItem('userInfo', JSON.stringify(data));
        navigate('/administration');
    } catch (error) {
        dispatch(userLoginFail(error.toString()));
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch(userLogout());
}