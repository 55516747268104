import React, {useEffect} from 'react';

import './Projets.css';
import {PlaceholderImage3} from '../../icons/PlaceholderImage3';
import ProjetSection from "../../components/Projet/ProjetSection";
import {useDispatch, useSelector} from "react-redux";
import {fetchProjets} from "../../redux/Slices/projetSlice";
import { LinkButton } from '../../components/Button2/LinkButton/LinkButton';

function Projets() {

    const dispatch = useDispatch();
    const { projets } = useSelector(state => state.projets); // Récupère les projets dans l'ordre enregistré

    useEffect(() => {
        dispatch(fetchProjets()); // Charger les projets au montage
    }, [dispatch]);

    return (
        <div className="nos-projets">
            <div className="content-section">
                <h1 className="text-wrapper-5">Nos projets</h1>
                 <ProjetSection projets={projets} />
            </div>
            <div className="CTA">
                <PlaceholderImage3 className="placeholder-image"/>
                <div className="auto-layout-vertical-3">
                    <div className="auto-layout-vertical-4">
                        <p className="text-wrapper-7">Envie de lancer votre projet ?</p>
                        <p className="p">
                            Nous croyons que chaque projet mérite une approche sur mesure. <br/>
                            Contactez-nous pour développer des solutions adaptées à vos utilisateurs. Que vous soyez en
                            phase
                            d’idée
                            ou déjà avancé, notre équipe vous accompagne à chaque étape, avec un focus sur l’expérience
                            utilisateur et
                            l’efficacité. <br/>
                            Donnons vie à vos ambitions ensemble. Contactez-nous dès maintenant !
                        </p>
                    </div>
                    <LinkButton
                        className="design-component-instance-node-2"
                        style="filled"
                        text="Nous contacter"
                        to={`/contact`}
                    />
                </div>
            </div>
        </div>
    );
}

export default Projets;