import axios from 'axios';
import {
    PARAMETRES_LIST_REQUEST,
    PARAMETRES_LIST_SUCCESS,
    PARAMETRES_LIST_FAIL,

    PARAMETRES_ADD_REQUEST,
    PARAMETRES_ADD_SUCCESS,
    PARAMETRES_ADD_FAIL,
    PARAMETRES_ADD_RESET,

    PARAMETRES_DELETE_REQUEST,
    PARAMETRES_DELETE_SUCCESS,
    PARAMETRES_DELETE_FAIL,
    PARAMETRES_DELETE_RESET,

    PARAMETRES_UPDATE_REQUEST,
    PARAMETRES_UPDATE_SUCCESS,
    PARAMETRES_UPDATE_FAIL,
    PARAMETRES_UPDATE_RESET,
} from '../constants/parametresConstants';

// Action to list all Parametres
export const listParametres = () => async (dispatch, getState) => {
    try {
        dispatch({ type: PARAMETRES_LIST_REQUEST });

        const { data } = await axios.get(`/api/param/parametres/`);

        dispatch({
            type: PARAMETRES_LIST_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: PARAMETRES_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Action to add a new Parametre
export const addParametre = (parametre) => async (dispatch, getState) => {
    try {
        dispatch({ type: PARAMETRES_ADD_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(`/api/param/parametres/`, parametre, config);

        dispatch({
            type: PARAMETRES_ADD_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: PARAMETRES_ADD_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Action to delete a Parametre
export const deleteParametre = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: PARAMETRES_DELETE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`/api/param/parametres/${id}/`, config);

        dispatch({
            type: PARAMETRES_DELETE_SUCCESS,
        });

    } catch (error) {
        dispatch({
            type: PARAMETRES_DELETE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Action to update a Parametre
export const updateParametre = (parametreId, parametre) => async (dispatch, getState) => {
    try {
        dispatch({ type: PARAMETRES_UPDATE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(`/api/param/parametres/${parametreId}/`, parametre, config);

        dispatch({
            type: PARAMETRES_UPDATE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: PARAMETRES_UPDATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Reset actions
export const addParametreReset = () => (dispatch) => {
    dispatch({ type: PARAMETRES_ADD_RESET });
};

export const deleteParametreReset = () => (dispatch) => {
    dispatch({ type: PARAMETRES_DELETE_RESET });
};

export const updateParametreReset = () => (dispatch) => {
    dispatch({ type: PARAMETRES_UPDATE_RESET });
};
