import React, { useState } from "react";
import './Rejoindre.css';
import CandidatureList from "./CandidatureList/CandidatureList";
import OffreEmploiList from "./OffreEmploiList/OffreEmploiList";
import AnswerCandidate from "./AnswerCandidate/AnswerCandidate";

function Rejoindre() {
    const [activeTab, setActiveTab] = useState("jobOffers");
    const [selectedJobId, setSelectedJobId] = useState(null); 
    const [filterJobId, setfilterJobId] = useState(null);
    const [emailData, setemailData] = useState(null);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleSelectOffer = (job_id) => {
        setSelectedJobId(job_id); 
        handleTabChange("jobOffers"); 
    };

    const handleSelectCandidate = (job_id) => {
        setfilterJobId(job_id); 
        handleTabChange("candidatures"); 
    };
    
    const handleAnswerCandidate = (data) => {
        setemailData(data); 
        handleTabChange("email");
    }

    return (
        <div className="rejoindre-admin">

            <div className="tabs">
                <span
                    className={`tab ${activeTab === "jobOffers" ? "active" : ""}`}
                    onClick={() => handleTabChange("jobOffers")}
                >
                    Offres d'emploi
                </span>
                <span
                    className={`tab ${activeTab === "candidatures" ? "active" : ""}`}
                    onClick={() => handleTabChange("candidatures")}
                >
                    Candidatures
                </span>
            </div>

            {activeTab === "jobOffers" && (
                <OffreEmploiList selectedJobId={selectedJobId} handleCandidateClick={handleSelectCandidate} /> 
            )}
            {activeTab === "candidatures" && (
                <CandidatureList filterJobId={filterJobId} handleJobOfferClick={handleSelectOffer} answerCandidate={handleAnswerCandidate} />
            )}
            {activeTab === "email" && (
                <AnswerCandidate data={emailData} />
            )}
        </div>
    );
}

export default Rejoindre;
