import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/Button/Button";
import logo from '../../assets/wired-outline-478-computer-display-hover-angle-1.gif';
import { isValidEmail } from "../../utils/emailUtils";
import { addAvertissement } from "../../redux/actions/avertissementActions";
import "./SiteEnConstruction.css";

export const SiteEnConstruction = () => {
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const avertissement = useSelector((state) => state.avertissement);
    const { loading, error, success } = avertissement;

    useEffect(() => {
        if (success) {
            setShowNotification(true);
            setEmail("");
            setIsEmailValid(true);
            setHasSubmitted(false);
            setTimeout(() => setShowNotification(false), 6000); // Cacher la notification après 6 secondes
        } else if (error) {
            setShowNotification(true);
            setTimeout(() => setShowNotification(false), 3000); // Cacher la notification après 3 secondes
        }
    }, [success, error]);

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsEmailValid(isValidEmail(email));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setHasSubmitted(true);

        if (isEmailValid) {
            // Envoyer l'email ou effectuer une autre action
            dispatch(addAvertissement(email));
            setModalOpen(false);
        } else {
            alert("Veuillez entrer une adresse email valide.");
        }
    };

    return (
        <div className="site-en-construction">
            <div className="frame-site-en-construction">
                <img
                    className="wired-outline"
                    alt="Wired outline"
                    src={logo}
                />

                <p className="text-wrapper">Quelque chose d’incroyable arrive !</p>

                <p className="le-tout-nouveau-site">
                    Le tout nouveau site de Coode-NT est en cours de développement !<br />
                    Nous avons hâte de vous montrer sa toute nouvelle identité graphique
                    ainsi que nos futurs projets !
                </p>

                <button className={`button filled button-instance`} onClick={() => setModalOpen(true)}>
                    <div className={`do-something design-component-instance-node}`}>Être alerté</div>
                </button>
                {/* Notification de succès ou d'erreur sous le bouton */}
                {showNotification && (
                    <div className={`notification ${success ? "success" : "error"}`}>
                        {success ? "Merci ! Vous serez alerté par mail dès que le site web sera lancé !" : "Une erreur s'est produite. Veuillez réessayer."}
                    </div>
                )}
            </div>

            <div className="div-wrapper">
                <p className="div">© 2024 COODE-NT. Tous droits réservés.</p>
            </div>

            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
                        <h2>Être alerté</h2>
                        <form onSubmit={handleSubmit}>
                            <label>
                                Email :
                                <input
                                    type="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                    className={!isEmailValid && hasSubmitted ? "invalid" : ""}
                                />
                            </label>
                            {!isEmailValid && (
                                <p className="error-message">Veuillez entrer une adresse email valide.</p>
                            )}
                            <button className={`button filled button-instance`} type="submit">
                                <div className={`do-something design-component-instance-node}`}>S'inscrire</div>
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};