// src/components/Footer/Footer.js
import React from 'react';
import { StyleOutlinedWrapper } from "../StyleOutlinedWrapper/StyleOutlinedWrapper";
import { Link } from 'react-router-dom';
import logo from '../../assets/coode-nt-white-1.png'
import './Footer.css'; // Assurez-vous que le chemin est correct

function Footer() {
  return (
    <footer>
      <div className={`frame design-component-instance-node-2`}>
        <div className="div-2">
          <div className="div-3">
            <img className="coode-nt-white" alt="Coode nt white" src={logo} />
            <p className="text-wrapper-2">Une idée de projet ?</p>
            <StyleOutlinedWrapper
              className="design-component-instance-node"
              divClassName="button-2"
              style="outlined"
              text="Contactez-nous"
            />
          </div>
          <div className="div-4">
            <div className="text-wrapper-3">Menu</div>
            <Link className="text-wrapper-2" to="/accueil">
              Accueil
            </Link>
            <Link className="text-wrapper-4" to="/about">
              À Propos
            </Link>
            <Link className="text-wrapper-4" to="/projets">
              Nos Projets
            </Link>
            <Link className="text-wrapper-4" to="/join">
              Nous Rejoindre
            </Link>
          </div>
          <div className="div-4">
            <Link className="text-wrapper-3" to="/contact">
            Contact
            </Link>
            <a href="mailto:contact@coode-nt.com" className="text-wrapper-4">contact@coode-nt.com</a>
            <div className="text-wrapper-2">Téléphone</div>

          </div>
          <div className="div-4">
            <div className="text-wrapper-3">Réseaux Sociaux</div>
            <div className="ellipse" />
          </div>
        </div>
        <p className="text-wrapper-2">© 2024 COODE-NT. Tous droits réservés.</p>
      </div>
    </footer>
  );
}

export default Footer;
