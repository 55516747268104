export const INFORMATION_LIST_REQUEST = 'INFORMATION_LIST_REQUEST';
export const INFORMATION_LIST_SUCCESS = 'INFORMATION_LIST_SUCCESS';
export const INFORMATION_LIST_FAIL = 'INFORMATION_LIST_FAIL';

export const INFORMATION_ADD_REQUEST = 'INFORMATION_ADD_REQUEST';
export const INFORMATION_ADD_SUCCESS = 'INFORMATION_ADD_SUCCESS';
export const INFORMATION_ADD_FAIL = 'INFORMATION_ADD_FAIL';
export const INFORMATION_ADD_RESET = 'INFORMATION_ADD_RESET';

export const INFORMATION_DELETE_REQUEST = 'INFORMATION_DELETE_REQUEST';
export const INFORMATION_DELETE_SUCCESS = 'INFORMATION_DELETE_SUCCESS';
export const INFORMATION_DELETE_FAIL = 'INFORMATION_DELETE_FAIL';
export const INFORMATION_DELETE_RESET = 'INFORMATION_DELETE_RESET';

export const INFORMATION_UPDATE_REQUEST = 'INFORMATION_UPDATE_REQUEST';
export const INFORMATION_UPDATE_SUCCESS = 'INFORMATION_UPDATE_SUCCESS';
export const INFORMATION_UPDATE_FAIL = 'INFORMATION_UPDATE_FAIL';
export const INFORMATION_UPDATE_RESET = 'INFORMATION_UPDATE_RESET';
