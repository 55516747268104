import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { informationListAction } from '../../redux/actions/informationActions';
import './Collaborateurs.css'; // Assurez-vous d'avoir le bon chemin

import { createMarkup } from '../../utils';

function Collaborateurs() {
    const dispatch = useDispatch();
    const informationList = useSelector((state) => state.informationList);
    const { informations, loading, error } = informationList;

    const [selectedInformation, setSelectedInformation] = useState(null);

    useEffect(() => {
        dispatch(informationListAction());
    }, [dispatch]);

    useEffect(() => {
        setSelectedInformation(informations
            .filter(information => information.type === "Collaborateur")
            .sort((a, b) => a.titre.localeCompare(b.titre))[0]);
    }, [informations]);

    const handleCardClick = (information) => {
        setSelectedInformation(information);
    };

    // Les gestionnaires onMouseEnter et onMouseLeave ne sont plus nécessaires
    // pour la logique d'affichage de la description.

    return (
        <div className="collaborateurs-container">
            <Helmet>
                <title>Qui sont nos collaborateurs</title>
                <meta name="description" content="Collaborateurs" />
                <meta name="keywords" content="services, coode-nt, domisimpli, googe play store, app store" />
            </Helmet>
            {loading && <p>Chargement...</p>}
            {error && <p>Erreur : {error}</p>}
            <div className="collaborateurs-grid">
                {informations && informations.length > 0 && informations
                    .filter(information => information.type === "Collaborateur")
                    .sort((a, b) => a.titre.localeCompare(b.titre))
                    .map(information => (
                        <div
                            key={information._id}
                            className={`collaborateur-card ${selectedInformation === information ? 'selected' : 'not-selected'}`}
                            onClick={() => handleCardClick(information)}
                        >
                            <img src={information.image} alt={information.titre} className="collaborateur-image" />
                            <h3>{information.titre}</h3>
                        </div>
                    ))}
            </div>
            {selectedInformation && (
                <div className="collaborateur-description">
                    <p className='information-description' dangerouslySetInnerHTML={createMarkup(selectedInformation.description)}></p>
                </div>
            )}
        </div>
    );
}

export default Collaborateurs;
