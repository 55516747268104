export const DISPOSABLE_EMAIL_LIST_REQUEST = 'DISPOSABLE_EMAIL_LIST_REQUEST';
export const DISPOSABLE_EMAIL_LIST_SUCCESS = 'DISPOSABLE_EMAIL_LIST_SUCCESS';
export const DISPOSABLE_EMAIL_LIST_FAIL = 'DISPOSABLE_EMAIL_LIST_FAIL';

export const DISPOSABLE_EMAIL_ADD_REQUEST = 'DISPOSABLE_EMAIL_ADD_REQUEST';
export const DISPOSABLE_EMAIL_ADD_SUCCESS = 'DISPOSABLE_EMAIL_ADD_SUCCESS';
export const DISPOSABLE_EMAIL_ADD_FAIL = 'DISPOSABLE_EMAIL_ADD_FAIL';
export const DISPOSABLE_EMAIL_ADD_RESET = 'DISPOSABLE_EMAIL_ADD_RESET';

export const DISPOSABLE_EMAIL_DELETE_REQUEST = 'DISPOSABLE_EMAIL_DELETE_REQUEST';
export const DISPOSABLE_EMAIL_DELETE_SUCCESS = 'DISPOSABLE_EMAIL_DELETE_SUCCESS';
export const DISPOSABLE_EMAIL_DELETE_FAIL = 'DISPOSABLE_EMAIL_DELETE_FAIL';
export const DISPOSABLE_EMAIL_DELETE_RESET = 'DISPOSABLE_EMAIL_DELETE_RESET';

export const DISPOSABLE_EMAIL_UPDATE_REQUEST = 'DISPOSABLE_EMAIL_UPDATE_REQUEST';
export const DISPOSABLE_EMAIL_UPDATE_SUCCESS = 'DISPOSABLE_EMAIL_UPDATE_SUCCESS';
export const DISPOSABLE_EMAIL_UPDATE_FAIL = 'DISPOSABLE_EMAIL_UPDATE_FAIL';
export const DISPOSABLE_EMAIL_UPDATE_RESET = 'DISPOSABLE_EMAIL_UPDATE_RESET';