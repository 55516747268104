/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const PlaceholderImage1 = ({ className,  height, width}) => {
  return (
    <svg
      className={`placeholder-image-1 ${className}`}
      fill="none"
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill="#EFF2F6" height={height} rx="24" width={width} />
      <path
        className="path"
        d="M324.5 237.333V162.667C324.5 156.8 319.7 152 313.833 152H239.167C233.3 152 228.5 156.8 228.5 162.667V237.333C228.5 243.2 233.3 248 239.167 248H313.833C319.7 248 324.5 243.2 324.5 237.333ZM257.833 208L271.167 224.053L289.833 200L313.833 232H239.167L257.833 208Z"
        fill="#9CB0C9"
      />
    </svg>
  );
};
