import {
    ADD_AVERTISSEMENT_REQUEST,
    ADD_AVERTISSEMENT_SUCCESS,
    ADD_AVERTISSEMENT_FAIL,
    ADD_AVERTISSEMENT_RESET
} from '../constants/avertissementConstants';

export const avertissementReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_AVERTISSEMENT_REQUEST:
            return { loading: true };
        case ADD_AVERTISSEMENT_SUCCESS:
            return { loading: false, success: true };
        case ADD_AVERTISSEMENT_FAIL:
            return { loading: false, error: action.payload };
        case ADD_AVERTISSEMENT_RESET:
            return {};
        default:
            return state;
    }
}