import React, { useEffect, useState } from "react";
import { StyleOutlinedWrapper } from "../../../../components/StyleOutlinedWrapper/StyleOutlinedWrapper";
import './CandidatureList.css';
import { useDispatch, useSelector } from "react-redux";
import { candidatureEmploiDeleteAction, candidatureEmploiListAction, candidatureEmploiUpdateAction } from "../../../../redux/actions/candidatureEmploiActions";
import { useNavigate } from "react-router-dom";

function CandidatureList({ handleJobOfferClick, filterJobId, answerCandidate }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isFilterJobId, setIsFilterJobId] = useState(filterJobId);
    const [openCandidatureId, setOpenCandidatureId] = useState(null); // État pour gérer l'ouverture des détails

    const candidatureEmploiList = useSelector((state) => state.candidatureEmploiList);
    const { candidatures, loading, error } = candidatureEmploiList;

    useEffect(() => {
        dispatch(candidatureEmploiListAction());
    }, [dispatch]);

    const handleDelete = (id) => {
        const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette candidature ?");
        if (isConfirmed) {
            dispatch(candidatureEmploiDeleteAction(id)).then(() => dispatch(candidatureEmploiListAction()));
        }
    };

    const filteredCandidatures = (candidatures) => isFilterJobId
        ? candidatures.filter(candidature => candidature.job_offer && !candidature.repondu && candidature.job_offer === isFilterJobId)
        : candidatures;

    const toggleCollapse = (id) => {
        setOpenCandidatureId(openCandidatureId === id ? null : id); // Toggle l'affichage des détails
    };

    const handleUpdateAnswer = (candidature) => {
        const answered = { repondu: true }
        dispatch(candidatureEmploiUpdateAction(candidature.id, answered)).then(() => dispatch(candidatureEmploiListAction()));
    }

    return (
        <div className="candidat-list">
            <span className="pointer-span" onClick={() => setIsFilterJobId(null)}>
                <StyleOutlinedWrapper className="button" style="filled" text="retirer filtre" />
            </span>
            {loading ? <p>Chargement...</p> : error ? <p>{error}</p> : (
                <table>
                    <thead>
                        <tr>
                            <th>Traitée</th>
                            <th>Civilité</th>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Email</th>
                            <th>Type de Poste</th>
                            <th>Offre</th>
                            <th>Supprimer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCandidatures(candidatures).map((candidature) => (
                            <React.Fragment key={candidature.id}>
                                <tr
                                    onClick={() => toggleCollapse(candidature.id)}
                                    className={openCandidatureId === candidature.id ? "clickable-row active" : "clickable-row"}
                                >
                                    <td style={{ textAlign: 'center' }}>{candidature.repondu ? (<span>oui</span>) : (<span>non</span>)}</td>
                                    <td>{candidature.civilite}</td>
                                    <td>{candidature.nom}</td>
                                    <td>{candidature.prenom}</td>
                                    <td>{candidature.email}</td>
                                    <td>{candidature.type_poste}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        {candidature.job_offer ? (
                                            <span onClick={() => handleJobOfferClick(candidature.job_offer)}>
                                                <StyleOutlinedWrapper className="button" style="filled" text="Offre" />
                                            </span>
                                        ) : 'Spontanée'}
                                    </td>
                                    <td>
                                        <span onClick={() => handleDelete(candidature.id)}>
                                            <StyleOutlinedWrapper className="button" style="filled" text="Supprimer" />
                                        </span>
                                    </td>
                                </tr>
                                {openCandidatureId === candidature.id && (
                                    <tr>
                                        <td colSpan="8">
                                            <div className="collapse-content">
                                                <p><strong>Civilité :</strong> {candidature.civilite}</p>
                                                <p><strong>Nom :</strong> {candidature.nom}</p>
                                                <p><strong>Prénom :</strong> {candidature.prenom}</p>
                                                <p><strong>Email :</strong> {candidature.email}</p>
                                                <p><strong>Téléphone :</strong> {candidature.telephone}</p>
                                                <p><strong>Type de Poste :</strong> {candidature.type_poste}</p>
                                                <p><strong>Date de candidature :</strong> {new Date(candidature.created_at).toLocaleString()}</p>
                                                <p><strong>Texte :</strong> {candidature.texte}</p>
                                                {/* Section pour les documents */}
                                                <div className="documents-section">
                                                    <h4>Documents :</h4>
                                                    {candidature.documents.length > 0 ? (
                                                        <ul>
                                                            {candidature.documents.map((doc) => (
                                                                <li key={doc.id}>
                                                                    <span href={doc.fichier} target="_blank" rel="noopener noreferrer">
                                                                        Document {doc.id} - {doc.fichier} - Téléchargé le {new Date(doc.created_at).toLocaleString()}
                                                                    </span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <p>Aucun document joint</p>
                                                    )}
                                                </div>
                                                <p className="candidat-answer-button">
                                                    {!candidature.repondu && <span onClick={() => { handleUpdateAnswer(candidature) }}>
                                                        <StyleOutlinedWrapper className="button" style="filled" text="Traitée" />
                                                    </span>}
                                                    <span onClick={() => answerCandidate(candidature)}>
                                                        <StyleOutlinedWrapper className="button" style="filled" text="Répondre" />
                                                    </span>
                                                </p>

                                            </div>
                                        </td>
                                    </tr>
                                )}

                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default CandidatureList;
