import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { informationListAction } from '../../redux/actions/informationActions';
import './Valeurs.css'; // Assurez-vous d'avoir le bon chemin pour votre fichier CSS

import { createMarkup } from '../../utils';

function Valeurs() {
    const dispatch = useDispatch();

    const informationList = useSelector((state) => state.informationList);
    const { informations, loading, error } = informationList;

    useEffect(() => {
        dispatch(informationListAction());
    }, [dispatch]);

    return (
        <div className="valeurs-container">
            <Helmet>
                <title>COODE-NT, une entreprises aux valeurs humanistes.</title>
                <meta name="description" content="Valeurs" />
                <meta name="keywords" content="services, coode-nt, domisimpli, googe play store, app store, valeurs" />
            </Helmet>
            {loading && <p>Chargement...</p>}
            {error && <p>Erreur : {error}</p>}
            {informations && informations.length > 0 && informations
            .filter((information) => information.type === "Valeurs")
            .map((information, index) => (
                <div key={information._id} className={`valeur-card ${index % 2 === 0 ? 'left' : 'right'}`}>
                    <img src={information.image} className="valeur-image"/>
                    <div className="valeur-text">
                        <h1>{information.titre}</h1>
                        <div className='valeur-description' dangerouslySetInnerHTML={createMarkup(information.description)}></div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Valeurs;
