import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import './Projet.css';
import {
    fetchProjets,
    addProjet,
    updateProjet,
    deleteProjet,
    resetSuccess,
    toggleActiveProjet,
    updateProjetsOrder,
} from '../../../redux/Slices/projetSlice';

import RichTextEditor from '../../../components/RichTextEditor/RichTextEditor';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faToggleOff, faToggleOn} from "@fortawesome/free-solid-svg-icons";

function Projet() {
    const dispatch = useDispatch();
    const { projets, loading, error, success, message } = useSelector(state => state.projets);
    const formRef = useRef(null);

    const [projects, setProjects] = useState([]);
    const [formState, setFormState] = useState({
        titre: '',
        slogan: '',
        description: '',
        urlSiteWeb: '',
        urlGoogleApp: '',
        urlAppleApp: '',
        image: null,
        editing: false,
        projectId: null,
    });

    // Récupére les projets depuis Redux
    useEffect(() => {
        dispatch(fetchProjets());
    }, [dispatch]);

    // Mettre à jour la liste locale des projets
    useEffect(() => {
        if (projets) {
            setProjects(projets);
        }
    }, [projets]);

    useEffect(() => {
        if (success) {
            toast.success(message || "Action réussie !");
        }
        if (error) {
            toast.error(message || "Une erreur est survenue.");
        }
        if (success || error) {
            setTimeout(() => {
                dispatch(resetSuccess());
            }, 2000);
        }
    }, [success, error, message, dispatch]);

    function validateForm() {
        if (!formState.titre.trim()) {
            alert("Le titre est obligatoire");
            return false;
        }
        return true;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value });
    };

    const handleImageChange = (e) => {
        setFormState({ ...formState, image: e.target.files[0] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const formData = new FormData();
        formData.append('titre', formState.titre || '');
        formData.append('slogan', formState.slogan || '');
        formData.append('description', formState.description || '');
        formData.append('url_google_app', formState.urlGoogleApp || '');
        formData.append('url_apple_app', formState.urlAppleApp || '');
        formData.append('url_site_web', formState.urlSiteWeb || '');

        if (formState.image) {
            formData.append('image', formState.image);
        } else if (formState.originalImage) {
            formData.append('image', formState.originalImage);
        }

        if (formState.editing) {
            dispatch(updateProjet({ id: formState.projectId, formData }));
        } else {
            dispatch(addProjet(formData));
        }
    };

    const resetForm = () => {
        setFormState({
            titre: '',
            slogan: '',
            description: '',
            urlGoogleApp: '',
            urlAppleApp: '',
            urlSiteWeb: '',
            image: null,
            originalImage: null,
            editing: false,
            projectId: null,
        });
    };

    const handleCancel = () => {
        resetForm(); // Réinitialise le formulaire
    };

    // Bascule l'état actif/inactif du projet
    const handleToggleActive = (projet) => {
        dispatch(toggleActiveProjet({ id: projet.id }));
    };

    const handleEdit = (projet) => {
        if (!projet) {
            console.error("Le projet sélectionné est invalide.");
            return;
        }
        setFormState({
            titre: projet.titre,
            slogan: projet.slogan,
            description: projet.description,
            urlGoogleApp: projet.urlGoogleApp,
            urlAppleApp: projet.urlAppleApp,
            urlSiteWeb: projet.urlSiteWeb,
            image: null,
            originalImage: projet.image,
            editing: true,
            projectId: projet.id,
        });
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleDelete = (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce projet ?")) {
            dispatch(deleteProjet(id));
        }
    };

    const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedProjects = Array.from(projects);
    const [removed] = reorderedProjects.splice(result.source.index, 1);
    reorderedProjects.splice(result.destination.index, 0, removed);

    // Mise à jour de l'état local
    setProjects(reorderedProjects);
    console.log("Nouvel ordre des projets :", reorderedProjects);

    // Dispatch de l'action pour enregistrer l'ordre dans Redux
    dispatch(updateProjetsOrder(reorderedProjects)); // Assure-toi que cette action est dispatchée correctement
};

    return (
        <div className="administration-projets-2">
            <div className="content-section">
                <h2 className="text-wrapper-1">Création d'un Projet</h2>
                <form ref={formRef} className="content-form-projet grid-form" onSubmit={handleSubmit}>
                    <div className="frame-2">
                        <label className="text-wrapper-2">Titre :</label>
                        <input className="field" type="text" name="titre" placeholder="Titre" value={formState.titre}
                               onChange={handleInputChange}/>
                    </div>
                    <div className="frame-2">
                        <label className="text-wrapper-2">Slogan :</label>
                        <input className="field" type="text" name="slogan" placeholder="Slogan" value={formState.slogan}
                               onChange={handleInputChange}/>
                    </div>
                    <div className="frame-2">
                        <label className="text-wrapper-2">Url GoogleApp :</label>
                        <input className="field" type="text" name="urlGoogleApp" placeholder="URL Google"
                               value={formState.urlGoogleApp}
                               onChange={handleInputChange}/>
                    </div>
                    <div className="frame-2">
                        <label className="text-wrapper-2">URL AppleApp :</label>
                        <input className="field" type="text" name="urlAppleApp" placeholder="URL Apple"
                               value={formState.urlAppleApp}
                               onChange={handleInputChange}/>
                    </div>
                    <div className="frame-2">
                        <label className="text-wrapper-2">URL du Site Web :</label>
                        <input className="field" type="text" name="urlSiteWeb" placeholder="URL site web"
                               value={formState.urlSiteWeb}
                               onChange={handleInputChange}/>
                    </div>
                    <div className="frame-2">
                        <label className="text-wrapper-2">Description</label>
                        <div className="text-editor">
                            <RichTextEditor value={formState.description}
                                            onChange={value => setFormState({ ...formState, description: value })}/>
                        </div>
                    </div>
                    <div className="frame-2">
                        <label className="text-wrapper-2">Image</label>
                            <div>
                                <img src={formState.originalImage} alt="Image actuelle" className="imported-image"/>
                                <p>Image actuelle</p>
                            </div>
                        <input className="field" type="file" onChange={handleImageChange}/>
                    </div>
                    <button className="button-project" type="submit">Enregistrer</button>
                    <button className="button-cancel" type="button" onClick={handleCancel}>Annuler</button>
                </form>
            </div>
            {loading && <p>Chargement...</p>}

            {/* Liste des projets avec Drag and Drop */}
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="projects">
                    {(provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef} className="projets-cards-container">
                            {projects.map((projet, index) => (
                                <Draggable key={projet.id} draggableId={projet.id.toString()} index={index}>
                                    {(provided) => (
                                        <li
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            className="projets-card-2"
                                            style={{ ...provided.draggableProps.style }}
                                        >
                                            <div className="detail-card">
                                                <h3>{projet.titre || 'Titre indisponible'}</h3>
                                                <p className="p">
                                                    {projet.slogan || ''}
                                                    <br />
                                                    <p dangerouslySetInnerHTML={{ __html: projet.description }}/>
                                                </p>
{/*                                                <button onClick={() => handleToggleActive(projet)}
                                                    style={{ alignItems: 'center', background: projet.active ? 'green' : 'gray' }}>
                                                    {projet.active ? 'Désactiver' : 'Activer'}
                                                </button>*/}

                                                <button onClick={() => handleToggleActive(projet)}
                                                    style={{
                                                        alignItems: 'center',
                                                        backgroundColor: projet.active ? 'green' : 'gray',
                                                        color: 'black',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={projet.active ? faToggleOn : faToggleOff} size="2x" />
                                                    <span style={{ marginLeft: '10px' }}>
                                                        {projet.active ? 'Activer' : 'Désactiver'}
                                                    </span>
                                                </button>
                                                <button onClick={() => handleEdit(projet)}>Modifier</button>
                                                <button onClick={() => handleDelete(projet.id)}>Supprimer</button>
                                            </div>
                                            <div>
                                                {projet.image && <img src={projet.image} alt={projet.titre} className="imported-image" />}
                                            </div>
                                        </li>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>

            <ToastContainer />
        </div>
    );
}

export default Projet;
