import {
    OFFRE_EMPLOI_LIST_REQUEST,
    OFFRE_EMPLOI_LIST_SUCCESS,
    OFFRE_EMPLOI_LIST_FAIL,

    OFFRE_EMPLOI_ADD_REQUEST,
    OFFRE_EMPLOI_ADD_SUCCESS,
    OFFRE_EMPLOI_ADD_FAIL,
    OFFRE_EMPLOI_ADD_RESET,

    OFFRE_EMPLOI_DELETE_REQUEST,
    OFFRE_EMPLOI_DELETE_SUCCESS,
    OFFRE_EMPLOI_DELETE_FAIL,
    OFFRE_EMPLOI_DELETE_RESET,

    OFFRE_EMPLOI_UPDATE_REQUEST,
    OFFRE_EMPLOI_UPDATE_SUCCESS,
    OFFRE_EMPLOI_UPDATE_FAIL,
    OFFRE_EMPLOI_UPDATE_RESET
} from '../constants/offreEmploiConstants';

// Compare this snippet from user-interface/src/redux/reducers/offreEmploiReducers.js:
export const offreEmploiListReducer = (state = { offreEmplois: [] }, action) => {
    switch (action.type) {
        case OFFRE_EMPLOI_LIST_REQUEST:
            return { loading: true, offreEmplois: [] };
        case OFFRE_EMPLOI_LIST_SUCCESS:
            return { loading: false, offreEmplois: action.payload };
        case OFFRE_EMPLOI_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/offreEmploiReducers.js:
export const offreEmploiAddReducer = (state = {}, action) => {
    switch (action.type) {
        case OFFRE_EMPLOI_ADD_REQUEST:
            return { loading: true };
        case OFFRE_EMPLOI_ADD_SUCCESS:
            return { loading: false, success: true, offreEmploi: action.payload };
        case OFFRE_EMPLOI_ADD_FAIL:
            return { loading: false, error: action.payload };
        case OFFRE_EMPLOI_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/offreEmploiReducers.js:
export const offreEmploiDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case OFFRE_EMPLOI_DELETE_REQUEST:
            return { loading: true };
        case OFFRE_EMPLOI_DELETE_SUCCESS:
            return { loading: false, success: true };
        case OFFRE_EMPLOI_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case OFFRE_EMPLOI_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/offreEmploiReducers.js:
export const offreEmploiUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case OFFRE_EMPLOI_UPDATE_REQUEST:
            return { loading: true };
        case OFFRE_EMPLOI_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case OFFRE_EMPLOI_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case OFFRE_EMPLOI_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
