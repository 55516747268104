import React, { useEffect, useState } from "react";
import ArticleEditor from '../../../../components/ArticleEditor/ArticleEditor';
import { StyleOutlinedWrapper } from "../../../../components/StyleOutlinedWrapper/StyleOutlinedWrapper";

import './OffreEmploiList.css';
import { listJobOffers, addJobOffer, updateJobOffer, deleteJobOffer } from "../../../../redux/actions/jobOfferActions";
import { useDispatch, useSelector } from "react-redux";
import { candidatureEmploiListAction } from "../../../../redux/actions/candidatureEmploiActions";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import OffreTable from "./OffreTable/OffreTable";

function OffreEmploiList({ selectedJobId, handleCandidateClick }) {
    const dispatch = useDispatch();
    const [selectedJobOffer, setSelectedJobOffer] = useState({
        id: null,
        title: '',
        position: '',
        location: '',
        skills_short: '',
        skills: '',
        missions: '',
        extra_skills: '',
        why_join: '',
        benefits_join: '',
        image: '',
        type: ''
    });
    const [ignoreSelectedJobId,  setIgnoreSelectedJobId] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [witchTextEditor, setWitchTextEditor] = useState('skills');
    const [imagePreview, setImagePreview] = useState(selectedJobOffer.image || '');
    const fileInputRef = React.useRef(null);
    const [errors, setErrors] = useState({});
    const jobOfferList = useSelector((state) => {
        return state.jobOfferList
    });
    const { jobOffers, loading, error } = jobOfferList;
    const candidatureEmploiList = useSelector((state) => {
        return state.candidatureEmploiList
    });
    const { candidatures, loading: candidaturesLoading, error: candidaturesError } = candidatureEmploiList;

    const tabs = [
        { key: 'missions', label: 'Tes missions', value: selectedJobOffer.missions },
        { key: 'skills', label: 'Ce que nous recherchons', value: selectedJobOffer.skills },
        { key: 'extra_skills', label: 'Et dans nos aspirations les plus audacieuses', value: selectedJobOffer.extra_skills },
        { key: 'why_join', label: 'Pourquoi nous rejoindre', value: selectedJobOffer.why_join },
        { key: 'benefits_join', label: 'Les avantages', value: selectedJobOffer.benefits_join },
    ];

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedJobOffer({ ...selectedJobOffer, image: file });
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setImagePreview(`data:image/jpeg;base64,${base64String}`);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        dispatch(listJobOffers());
        dispatch(candidatureEmploiListAction());
    }, [dispatch]);

    useEffect(() => {
        if (selectedJobId && jobOffers.length > 0 && !ignoreSelectedJobId) {
            const offer = jobOffers.find((offer) => offer.id === selectedJobId);
            if (offer) {
                handleEditOffer(offer);
            }
        }
    }, [selectedJobId, jobOffers]);

    const setExportHTMLBeforeCreate = () => {
        const isConfirmed = window.confirm(selectedJobOffer.id ?
            handleconfirm("Voulez vous modifier cette offre ?") :
            handleconfirm("Voulez vous créer une offre ?"));
        if (isConfirmed) {
            handleCreateOrUpdate()
        }
    }

    const handleconfirm = (string) => {
        let stringToreturn = string
        if (!selectedJobOffer.skills.trim()) {
            stringToreturn = stringToreturn.concat('\nVous n\'avez pas renseigné de skills.')
        }
        if (!selectedJobOffer.missions.trim()) {
            stringToreturn = stringToreturn.concat('\nVous n\'avez pas renseigné de missions.')
        }
        if (!selectedJobOffer.extra_skills.trim()) {
            stringToreturn = stringToreturn.concat('\nVous n\'avez pas renseigné de extra_skills.')
        }
        if (!selectedJobOffer.why_join.trim()) {
            stringToreturn = stringToreturn.concat('\nVous n\'avez pas renseigné de why_join.')
        }
        if (!selectedJobOffer.benefits_join.trim()) {
            stringToreturn = stringToreturn.concat('\nVous n\'avez pas renseigné de benefits_join.')
        }
        return stringToreturn;
    }

    const validateForm = () => {
        const newErrors = {};
        if (!selectedJobOffer.title.trim()) newErrors.title = "Le titre est requis";
        if (!selectedJobOffer.position.trim()) newErrors.position = "L'intitulé du poste est requis";
        if (!selectedJobOffer.location.trim()) newErrors.location = "La localisation est requise";
        if (!selectedJobOffer.skills_short.trim()) newErrors.skills_short = "La description des compétences est requise";
        if (!selectedJobOffer.type.trim()) newErrors.type = "Le type de poste est requis";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Retourne true si pas d'erreurs
    };
    const handleExportedHtml = (html) => {
        if (witchTextEditor === 'skills') {
            setSelectedJobOffer({ ...selectedJobOffer, skills: html });
        }
        if (witchTextEditor === 'missions') {
            setSelectedJobOffer({ ...selectedJobOffer, missions: html });
        }
        if (witchTextEditor === 'extra_skills') {
            setSelectedJobOffer({ ...selectedJobOffer, extra_skills: html });
        }
        if (witchTextEditor === 'why_join') {
            setSelectedJobOffer({ ...selectedJobOffer, why_join: html });
        }
        if (witchTextEditor === 'benefits_join') {
            setSelectedJobOffer({ ...selectedJobOffer, benefits_join: html });
        }

    }
    const handleClickedEditorTab = (newTabValue) => {
        setWitchTextEditor(newTabValue)
    }
    const handleCreateOrUpdate = () => {
        if (validateForm()) { // Valider avant de soumettre
            const jobOfferData = new FormData(); // Utiliser FormData pour gérer les fichiers
            jobOfferData.append('title', selectedJobOffer.title);
            jobOfferData.append('position', selectedJobOffer.position);
            jobOfferData.append('location', selectedJobOffer.location);
            jobOfferData.append('skills_short', selectedJobOffer.skills_short);
            jobOfferData.append('skills', selectedJobOffer.skills);
            jobOfferData.append('missions', selectedJobOffer.missions);
            jobOfferData.append('extra_skills', selectedJobOffer.extra_skills);
            jobOfferData.append('why_join', selectedJobOffer.why_join);
            jobOfferData.append('benefits_join', selectedJobOffer.benefits_join);
            jobOfferData.append('type', selectedJobOffer.type);
            if (selectedJobOffer.image) {
                jobOfferData.append('image', selectedJobOffer.image);
            }

            if (selectedJobOffer.id) {
                dispatch(updateJobOffer(selectedJobOffer.id, jobOfferData)).then(() => dispatch(listJobOffers()));
            } else {
                dispatch(addJobOffer(jobOfferData)).then(() => dispatch(listJobOffers()));
            }

            // Réinitialiser le formulaire après la création ou la mise à jour
            resetForm();
        }
    };

    const resetForm = () => {
        setIgnoreSelectedJobId(true)
        setSelectedJobOffer({
            id: null,
            title: '',
            position: '',
            location: '',
            skills_short: '',
            skills: '',
            missions: '',
            extra_skills: '',
            why_join: '',
            benefits_join: '',
            image: null,
            type: ''
        });
        setImagePreview('');
        setErrors({});
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleFormOffer = (value) => {
        resetForm();
        setIsFormVisible(value);
    };

    const handleEditOffer = (offer) => {
        // Pré-remplir le formulaire avec l'offre existante
        setSelectedJobOffer(offer);
        setImagePreview(offer.image || '');
        setIsFormVisible(true); // Afficher le formulaire
    };

    const handleWaitingCandidate = (offerId) => {
        const hasJobOffer = candidatures && candidatures.filter(candidature => candidature.job_offer && !candidature.repondu)
        return hasJobOffer && hasJobOffer.filter(candidature => candidature.job_offer === offerId).length
    }



    const handleTabContent = (key) => {
        const tabContent = tabs.find(tab => tab.key === key);
        return tabContent ? (
            <div className="frame-4">
                <div className="text-wrapper-9">{tabContent.label}</div>
                <span className="answer-editor">

                    <RichTextEditor
                        value={tabContent.value}
                        onChange={handleExportedHtml}
                    />
                </span>
            </div>
        ) : null;
    };

    return (
        <div className="job-offers-list">
            <div className="add-offer-button">
                <span className="pointer-span" onClick={() => handleFormOffer(true)}>
                <StyleOutlinedWrapper className="button new" style="filled" text="Ajouter" />
            </span>
            {isFormVisible && ( <span className="pointer-span" onClick={() => handleFormOffer(false)}>
                <StyleOutlinedWrapper className="button undo" style="filled" text="Annuler" />
            </span> )}
            </div>
            {isFormVisible ? (
                <div className="offers-editor">
                    <div className="frame-4">
                        <div className="text-wrapper-9">Titre de l'offre</div>
                        <input
                            type="text"
                            value={selectedJobOffer.title}
                            onChange={(e) => setSelectedJobOffer({ ...selectedJobOffer, title: e.target.value })}
                            placeholder="Titre de l'offre"
                        />
                        {errors.title && <span className="error-text">{errors.title}</span>}
                    </div>
                    <div className="frame-4">
                        <div className="text-wrapper-9">Intitulé du poste</div>
                        <input
                            type="text"
                            value={selectedJobOffer.position}
                            onChange={(e) => setSelectedJobOffer({ ...selectedJobOffer, position: e.target.value })}
                            placeholder="Poste"
                        />
                        {errors.position && <span className="error-text">{errors.position}</span>}
                    </div>
                    <div className="frame-4">
                        <div className="text-wrapper-9">Lieux</div>
                        <input
                            type="text"
                            value={selectedJobOffer.location}
                            onChange={(e) => setSelectedJobOffer({ ...selectedJobOffer, location: e.target.value })}
                            placeholder="Localisation"
                        />
                        {errors.location && <span className="error-text">{errors.location}</span>}
                    </div>
                    <div className="frame-4">
                        <div className="text-wrapper-9">Compétences (déscription courtes)</div>
                        <input
                            type="text"
                            value={selectedJobOffer.skills_short}
                            onChange={(e) => setSelectedJobOffer({ ...selectedJobOffer, skills_short: e.target.value })}
                            placeholder="Compétences courtes"
                        />
                        {errors.skills_short && <span className="error-text">{errors.skills_short}</span>}
                    </div>
                    <div className="frame-4">
                        <div className="text-wrapper-9">Type d'emploi</div>
                        <input
                            type="text"
                            value={selectedJobOffer.type}
                            onChange={(e) => setSelectedJobOffer({ ...selectedJobOffer, type: e.target.value })}
                            placeholder="type de poste"
                        />
                        {errors.type && <span className="error-text">{errors.type}</span>}
                    </div>
                    <div className="frame-4">
                        <div className="text-wrapper-9">Image</div>
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{ marginBottom: '1rem' }}
                        />
                    </div>
                    {selectedJobOffer.image && !imagePreview ? (
                        <img
                            src={selectedJobOffer.image}
                            alt="offer"
                            className="imported-image"
                        />
                    ) : (
                        imagePreview && (
                            <img src={imagePreview} alt="Image preview" className="imported-image" />
                        )
                    )}

                    <div className={`editor-tabs ${witchTextEditor}`}>
                        {tabs.map(tab => (
                            <div
                                key={tab.key}
                                onClick={() => handleClickedEditorTab(tab.key)}
                                className={`editor-tab ${witchTextEditor === tab.key ? 'active' : ''}`}
                            >
                                {tab.label}
                            </div>
                        ))}
                    </div>
                    {handleTabContent(witchTextEditor)}


                    <div className="button-form">
                        <span className="pointer-span" onClick={setExportHTMLBeforeCreate}>
                            <StyleOutlinedWrapper className="button-3" style="filled" text={selectedJobOffer.id ? 'Mettre à jour' : 'Créer'} />
                        </span>

                        <span className="pointer-span" onClick={() => handleFormOffer(false)}>
                            <StyleOutlinedWrapper className="button-3" style="filled" text="Annuler" />
                        </span>
                    </div>

                </div>
            ) :
                (<OffreTable
                    handleEditOffer={handleEditOffer}
                    handleCandidateClick={handleCandidateClick}
                    handleWaitingCandidate={handleWaitingCandidate}
                />)}

        </div>
    );
}

export default OffreEmploiList;
