import React from 'react';

function Partenaire({ partenaire }) {
  return (
    <div className="partenaire-card">
      <div className="partenaire-nom-image">
        <h1>{partenaire.nom}</h1>
        <img src={partenaire.image} alt={partenaire.nom} className="partenaire-image"/>
      </div>
      <div className="partenaire-info">
        <h2>{partenaire.titre}</h2>
        <p>{partenaire.description}</p>
      </div>
      <div className="partenaire-action">
        <a href={partenaire.url_site_web} target="_blank" rel="noopener noreferrer" className="btn-visiter">Visiter le site</a>
      </div>
    </div>
  );
}

export default Partenaire;
