import React, { useEffect, useState } from "react";
import './Accueil.css';
import { useDispatch, useSelector } from "react-redux";
import { listParametres, addParametre, updateParametre, deleteParametre } from "../../../redux/actions/parametresActions";
import { StyleOutlinedWrapper } from "../../../components/StyleOutlinedWrapper/StyleOutlinedWrapper";

function Accueil() {
    const dispatch = useDispatch();

    const parametresList = useSelector((state) => {
        return state.parametresList;
    });
    const { parametres, loading, error } = parametresList;

    // État local pour gérer les champs du formulaire
    const [parametreData, setParametreData] = useState({
        id: null, // Ajout de l'ID pour la mise à jour
        nom: "",
        valeur: "",
        actif: false
    });

    // État local pour contrôler la visibilité du formulaire
    const [showForm, setShowForm] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // Mode édition

    // Fonction pour gérer les changements dans les champs du formulaire
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setParametreData({
            ...parametreData,
            [name]: type === "checkbox" ? checked : value
        });
    };

    // Fonction pour gérer la soumission du formulaire
    const handleSubmit = (e) => {
        e.preventDefault();
        if (isEditMode) {
            if (window.confirm("Êtes-vous sûr de vouloir modifier ce paramètre ?")) {
                dispatch(updateParametre(parametreData.id, parametreData)).then(() => dispatch(listParametres()));
            }
        } else {
            // Ajout de nouveau paramètre
            dispatch(addParametre(parametreData)).then(() => dispatch(listParametres()));
        }
        setParametreData({ nom: "", valeur: "", actif: false });
        setShowForm(false); 
        setIsEditMode(false); 
    };

    useEffect(() => {
        dispatch(listParametres());
    }, [dispatch]);

    // Fonction pour afficher/masquer le formulaire
    const toggleForm = () => {
        setShowForm(!showForm); // Utiliser la valeur actuelle de showForm
        if (!showForm) {
            setParametreData({ nom: "", valeur: "", actif: false });
            setIsEditMode(false); // Réinitialiser le mode édition si on crée un nouveau paramètre
        }
    };
    
    // Fonction pour éditer un paramètre existant
    const handleEdit = (parametre) => {
        if (window.confirm("Êtes-vous sûr de vouloir modifier ce paramètre ?")) {
            setParametreData({
                id: parametre.id, // Inclure l'ID pour l'édition
                nom: parametre.nom,
                valeur: parametre.valeur,
                actif: parametre.actif
            });
            setIsEditMode(true); // Activer le mode édition
            setShowForm(true); // Afficher le formulaire
        }
    };

    // Fonction pour supprimer un paramètre
    const handleDelete = (parametreId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce paramètre ?")) {
            dispatch(deleteParametre(parametreId)).then(() => dispatch(listParametres()));
        }
    };

    return (
        <div className="accueil-admin">
            <h2>Gestion des Paramètres</h2>

            {!showForm && (
                <span className="pointer-span" onClick={toggleForm}>
                    <StyleOutlinedWrapper className="button" style="filled" text="Nouveau" />
                </span>
            )}

            {showForm && (
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="nom">Nom:</label>
                        <input
                            type="text"
                            id="nom"
                            name="nom"
                            value={parametreData.nom}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="valeur">Valeur:</label>
                        <input
                            type="text"
                            id="valeur"
                            name="valeur"
                            value={parametreData.valeur}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="actif">
                            <input
                                type="checkbox"
                                id="actif"
                                name="actif"
                                checked={parametreData.actif}
                                onChange={handleChange}
                            />
                            Actif
                        </label>
                    </div>
                    <div>
                        <button type="submit">{isEditMode ? "Modifier Paramètre" : "Ajouter Paramètre"}</button>
                        <span className="pointer-span" onClick={toggleForm}>
                            <StyleOutlinedWrapper className="button" style="filled" text="Annuler" />
                        </span>
                    </div>
                </form>
            )}

            {loading ? <p>Chargement...</p> : error ? <p>{error}</p> : (
                <table>
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Valeur</th>
                            <th>Actif</th>
                            <th>Modifier</th>
                            <th>Supprimer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {parametres && parametres.map((parametre) => (
                            <tr key={parametre.id}>
                                <td className="long-text">{parametre.nom}</td>
                                <td className="long-text">{parametre.valeur}</td>
                                <td className="long-text">{parametre.actif ? "(Actif)" : "(Inactif)"}</td>
                                <td className="button-td">
                                    <span className="pointer-span" onClick={() => handleEdit(parametre)}>
                                        <StyleOutlinedWrapper className="button" style="filled" text="Modifier" />
                                    </span>
                                </td>
                                <td className="button-td">
                                    <span className="pointer-span" onClick={() => handleDelete(parametre.id)}>
                                        <StyleOutlinedWrapper className="button" style="filled" text="Supprimer" />
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default Accueil;
