import {
    DISPOSABLE_EMAIL_LIST_REQUEST,
    DISPOSABLE_EMAIL_LIST_SUCCESS,
    DISPOSABLE_EMAIL_LIST_FAIL,

    DISPOSABLE_EMAIL_ADD_REQUEST,
    DISPOSABLE_EMAIL_ADD_SUCCESS,
    DISPOSABLE_EMAIL_ADD_FAIL,
    DISPOSABLE_EMAIL_ADD_RESET,

    DISPOSABLE_EMAIL_DELETE_REQUEST,
    DISPOSABLE_EMAIL_DELETE_SUCCESS,
    DISPOSABLE_EMAIL_DELETE_FAIL,
    DISPOSABLE_EMAIL_DELETE_RESET,

    DISPOSABLE_EMAIL_UPDATE_REQUEST,
    DISPOSABLE_EMAIL_UPDATE_SUCCESS,
    DISPOSABLE_EMAIL_UPDATE_FAIL,
    DISPOSABLE_EMAIL_UPDATE_RESET,
} from '../constants/emailJetableConstants';

import axios from 'axios';

export const listDisposableEmails = () => async (dispatch) => {
    try {
        dispatch({ type: DISPOSABLE_EMAIL_LIST_REQUEST });

        const { data } = await axios.get(`/api/disposable_email/`);

        dispatch({
            type: DISPOSABLE_EMAIL_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DISPOSABLE_EMAIL_LIST_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
}

export const addDisposableEmail = (email) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DISPOSABLE_EMAIL_ADD_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(
            `/api/disposable_email/add/`,
            { email },
            config
        )

        dispatch({
            type: DISPOSABLE_EMAIL_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: DISPOSABLE_EMAIL_ADD_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
}

export const deleteDisposableEmail = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DISPOSABLE_EMAIL_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        await axios.delete(
            `/api/disposable_email/delete/${id}/`,
            config
        )

        dispatch({ type: DISPOSABLE_EMAIL_DELETE_SUCCESS })

    } catch (error) {
        dispatch({
            type: DISPOSABLE_EMAIL_DELETE_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
}

export const updateDisposableEmail = (email) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DISPOSABLE_EMAIL_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/disposable_email/update/${email.id}/`,
            email,
            config
        )

        dispatch({
            type: DISPOSABLE_EMAIL_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: DISPOSABLE_EMAIL_UPDATE_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
}

export const resetAddDisposableEmail = () => (dispatch) => {
    dispatch({ type: DISPOSABLE_EMAIL_ADD_RESET });
}

export const resetDeleteDisposableEmail = () => (dispatch) => {
    dispatch({ type: DISPOSABLE_EMAIL_DELETE_RESET });
}

export const resetUpdateDisposableEmail = () => (dispatch) => {
    dispatch({ type: DISPOSABLE_EMAIL_UPDATE_RESET });
}
