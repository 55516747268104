/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const PlaceholderImage4 = ({ className }) => {
  return (
    <svg
      className={`placeholder-image-4 ${className}`}
      fill="none"
      height="201"
      viewBox="0 0 543 201"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill="#EFF2F6" height="201" rx="24" width="543" />
      <path
        className="path"
        d="M319.5 137.833V63.1667C319.5 57.3 314.7 52.5 308.833 52.5H234.167C228.3 52.5 223.5 57.3 223.5 63.1667V137.833C223.5 143.7 228.3 148.5 234.167 148.5H308.833C314.7 148.5 319.5 143.7 319.5 137.833ZM252.833 108.5L266.167 124.553L284.833 100.5L308.833 132.5H234.167L252.833 108.5Z"
        fill="#9CB0C9"
      />
    </svg>
  );
};
