import {
    CONTACT_LIST_REQUEST,
    CONTACT_LIST_SUCCESS,
    CONTACT_LIST_FAIL,

    CONTACT_ADD_REQUEST,
    CONTACT_ADD_SUCCESS,
    CONTACT_ADD_FAIL,
    CONTACT_ADD_RESET,

    CONTACT_DELETE_REQUEST,
    CONTACT_DELETE_SUCCESS,
    CONTACT_DELETE_FAIL,
    CONTACT_DELETE_RESET,

    CONTACT_UPDATE_REQUEST,
    CONTACT_UPDATE_SUCCESS,
    CONTACT_UPDATE_FAIL,
    CONTACT_UPDATE_RESET,

    OBJET_CONTACT_LIST_REQUEST,
    OBJET_CONTACT_LIST_SUCCESS,
    OBJET_CONTACT_LIST_FAIL,

    OBJET_CONTACT_ADD_REQUEST,
    OBJET_CONTACT_ADD_SUCCESS,
    OBJET_CONTACT_ADD_FAIL,
    OBJET_CONTACT_ADD_RESET,

    OBJET_CONTACT_DELETE_REQUEST,
    OBJET_CONTACT_DELETE_SUCCESS,
    OBJET_CONTACT_DELETE_FAIL,
    OBJET_CONTACT_DELETE_RESET,

    OBJET_CONTACT_UPDATE_REQUEST,
    OBJET_CONTACT_UPDATE_SUCCESS,
    OBJET_CONTACT_UPDATE_FAIL,
    OBJET_CONTACT_UPDATE_RESET
} from '../constants/contactConstants';

// ========= REDUCERS POUR LES CONTACTS =========

// Réducteur pour la liste des contacts
export const contactListReducer = (state = { contacts: [] }, action) => {
    switch (action.type) {
        case CONTACT_LIST_REQUEST:
            return { loading: true, contacts: [] };
        case CONTACT_LIST_SUCCESS:
            return { loading: false, contacts: action.payload };
        case CONTACT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Réducteur pour l'ajout de contact
export const contactAddReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_ADD_REQUEST:
            return { loading: true };
        case CONTACT_ADD_SUCCESS:
            return { loading: false, success: true, contact: action.payload };
        case CONTACT_ADD_FAIL:
            return { loading: false, error: action.payload };
        case CONTACT_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Réducteur pour la suppression de contact
export const contactDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_DELETE_REQUEST:
            return { loading: true };
        case CONTACT_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CONTACT_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case CONTACT_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Réducteur pour la mise à jour de contact
export const contactUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_UPDATE_REQUEST:
            return { loading: true };
        case CONTACT_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case CONTACT_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case CONTACT_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}

// ========= REDUCERS POUR LES OBJETCONTACTS =========

// Réducteur pour la liste des objets de contact
export const objetContactListReducer = (state = { objetContacts: [] }, action) => {
    switch (action.type) {
        case OBJET_CONTACT_LIST_REQUEST:
            return { loading: true, objetContacts: [] };
        case OBJET_CONTACT_LIST_SUCCESS:
            return { loading: false, objetContacts: action.payload };
        case OBJET_CONTACT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

// Réducteur pour l'ajout d'un objet de contact
export const objetContactAddReducer = (state = {}, action) => {
    switch (action.type) {
        case OBJET_CONTACT_ADD_REQUEST:
            return { loading: true };
        case OBJET_CONTACT_ADD_SUCCESS:
            return { loading: false, success: true, objetContact: action.payload };
        case OBJET_CONTACT_ADD_FAIL:
            return { loading: false, error: action.payload };
        case OBJET_CONTACT_ADD_RESET:
            return {};
        default:
            return state;
    }
};

// Réducteur pour la suppression d'un objet de contact
export const objetContactDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case OBJET_CONTACT_DELETE_REQUEST:
            return { loading: true };
        case OBJET_CONTACT_DELETE_SUCCESS:
            return { loading: false, success: true };
        case OBJET_CONTACT_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case OBJET_CONTACT_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

// Réducteur pour la mise à jour d'un objet de contact
export const objetContactUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case OBJET_CONTACT_UPDATE_REQUEST:
            return { loading: true };
        case OBJET_CONTACT_UPDATE_SUCCESS:
            return { loading: false, success: true, objetContact: action.payload };
        case OBJET_CONTACT_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case OBJET_CONTACT_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};
