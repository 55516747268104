import {
    PARAMETRES_LIST_REQUEST,
    PARAMETRES_LIST_SUCCESS,
    PARAMETRES_LIST_FAIL,

    PARAMETRES_ADD_REQUEST,
    PARAMETRES_ADD_SUCCESS,
    PARAMETRES_ADD_FAIL,
    PARAMETRES_ADD_RESET,

    PARAMETRES_DELETE_REQUEST,
    PARAMETRES_DELETE_SUCCESS,
    PARAMETRES_DELETE_FAIL,
    PARAMETRES_DELETE_RESET,

    PARAMETRES_UPDATE_REQUEST,
    PARAMETRES_UPDATE_SUCCESS,
    PARAMETRES_UPDATE_FAIL,
    PARAMETRES_UPDATE_RESET
} from '../constants/parametresConstants';

// Réducteur pour la liste des parametres
export const parametresListReducer = (state = { parametres: [] }, action) => {
    switch (action.type) {
        case PARAMETRES_LIST_REQUEST:
            return { loading: true, parametres: [] };
        case PARAMETRES_LIST_SUCCESS:
            return { loading: false, parametres: action.payload };
        case PARAMETRES_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

// Réducteur pour l'ajout de parametre
export const parametresAddReducer = (state = {}, action) => {
    switch (action.type) {
        case PARAMETRES_ADD_REQUEST:
            return { loading: true };
        case PARAMETRES_ADD_SUCCESS:
            return { loading: false, success: true, parametre: action.payload };
        case PARAMETRES_ADD_FAIL:
            return { loading: false, error: action.payload };
        case PARAMETRES_ADD_RESET:
            return {};
        default:
            return state;
    }
};

// Réducteur pour la suppression de parametre
export const parametresDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PARAMETRES_DELETE_REQUEST:
            return { loading: true };
        case PARAMETRES_DELETE_SUCCESS:
            return { loading: false, success: true };
        case PARAMETRES_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case PARAMETRES_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

// Réducteur pour la mise à jour de parametre
export const parametresUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case PARAMETRES_UPDATE_REQUEST:
            return { loading: true };
        case PARAMETRES_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case PARAMETRES_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case PARAMETRES_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};
