export const CANDIDATURE_LIST_REQUEST = 'CANDIDATURE_LIST_REQUEST';
export const CANDIDATURE_LIST_SUCCESS = 'CANDIDATURE_LIST_SUCCESS';
export const CANDIDATURE_LIST_FAIL = 'CANDIDATURE_LIST_FAIL';

export const CANDIDATURE_ADD_REQUEST = 'CANDIDATURE_ADD_REQUEST';
export const CANDIDATURE_ADD_SUCCESS = 'CANDIDATURE_ADD_SUCCESS';
export const CANDIDATURE_ADD_FAIL = 'CANDIDATURE_ADD_FAIL';
export const CANDIDATURE_ADD_RESET = 'CANDIDATURE_ADD_RESET';

export const CANDIDATURE_DELETE_REQUEST = 'CANDIDATURE_DELETE_REQUEST';
export const CANDIDATURE_DELETE_SUCCESS = 'CANDIDATURE_DELETE_SUCCESS';
export const CANDIDATURE_DELETE_FAIL = 'CANDIDATURE_DELETE_FAIL';
export const CANDIDATURE_DELETE_RESET = 'CANDIDATURE_DELETE_RESET';

export const CANDIDATURE_UPDATE_REQUEST = 'CANDIDATURE_UPDATE_REQUEST';
export const CANDIDATURE_UPDATE_SUCCESS = 'CANDIDATURE_UPDATE_SUCCESS';
export const CANDIDATURE_UPDATE_FAIL = 'CANDIDATURE_UPDATE_FAIL';
export const CANDIDATURE_UPDATE_RESET = 'CANDIDATURE_UPDATE_RESET';
