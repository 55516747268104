export const PROJET_LIST_REQUEST = 'PROJET_LIST_REQUEST';
export const PROJET_LIST_SUCCESS = 'PROJET_LIST_SUCCESS';
export const PROJET_LIST_FAIL = 'PROJET_LIST_FAIL';

export const PROJET_ADD_REQUEST = 'PROJET_ADD_REQUEST';
export const PROJET_ADD_SUCCESS = 'PROJET_ADD_SUCCESS';
export const PROJET_ADD_FAIL = 'PROJET_ADD_FAIL';
export const PROJET_ADD_RESET = 'PROJET_ADD_RESET';

export const PROJET_DELETE_REQUEST = 'PROJET_DELETE_REQUEST';
export const PROJET_DELETE_SUCCESS = 'PROJET_DELETE_SUCCESS';
export const PROJET_DELETE_FAIL = 'PROJET_DELETE_FAIL';
export const PROJET_DELETE_RESET = 'PROJET_DELETE_RESET';

export const PROJET_UPDATE_REQUEST = 'PROJET_UPDATE_REQUEST';
export const PROJET_UPDATE_SUCCESS = 'PROJET_UPDATE_SUCCESS';
export const PROJET_UPDATE_FAIL = 'PROJET_UPDATE_FAIL';
export const PROJET_UPDATE_RESET = 'PROJET_UPDATE_RESET';
