import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import JobCard from '../../components/JobCard/JobCard';
import "./style.css";
import { listJobOffers } from "../../redux/actions/jobOfferActions";
import { useNavigate } from "react-router-dom";
import { LinkButton } from "../../components/Button2/LinkButton/LinkButton";

export const NousRejoindre = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jobOfferList = useSelector((state) => state.jobOfferList);
  const { jobOffers, loading, error } = jobOfferList;


  useEffect(() => {
    dispatch(listJobOffers());
  }, [dispatch]);

  const handleApplyClick = () => {
    navigate("/candidate");
  }

  return (
    <div className="nous-rejoindre">
      <div className="content-section">
        <div className="auto-layout-vertical">
          <div className="auto-layout-vertical-2">
            <div className="text-wrapper-5">Nous rejoindre</div>
            <div className="text-wrapper-6">Petit texte</div>
          </div>
          <div className="separation-line margin-zero" />
          <div className="job-offers-grid">
          {loading ? (
                    <p>Chargement des membres projets...</p>
                ) : error ? (
                    <p>Aucune offre trouvée {console.log('Erreur lors de la récupération des offres : ',error)}</p>
                ) : (
                  jobOffers && jobOffers.map((job, index) => (
                    <JobCard
                    key={index} 
                    title={job.title}
                    position={job.position}
                    location={job.location}
                    skills={job.skills}
                    skills_short={job.skills_short}
                    extra_skills={job.extra_skills}
                    missions={job.missions}
                    why_join={job.why_join}
                    benefits_join={job.benefits_join}
                    id={job.id}
                    />
                  ))
                )}
          </div>
        </div>
        <span className="pointer-span" onClick={handleApplyClick}>
          <LinkButton 
            className="design-component-instance-node-2" 
            style="filled" 
            text="Candidature spontanée" />
        </span>
      </div>
    </div>
  );
};
