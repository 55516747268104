import React from "react";

export const PlaceholderImage1 = ({ className }) => {
  return (
    <svg
      className={`placeholder-image-1 ${className}`}
      fill="none"
      height="261"
      viewBox="0 0 542 261"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill="#EFF2F6" height="261" rx="24" width="542" />
      <path
        className="path"
        d="M319 167.833V93.1667C319 87.3 314.2 82.5 308.333 82.5H233.667C227.8 82.5 223 87.3 223 93.1667V167.833C223 173.7 227.8 178.5 233.667 178.5H308.333C314.2 178.5 319 173.7 319 167.833ZM252.333 138.5L265.667 154.553L284.333 130.5L308.333 162.5H233.667L252.333 138.5Z"
        fill="#9CB0C9"
      />
    </svg>
  );
};
