import axios from 'axios';
import {
    JOBOFFER_LIST_REQUEST,
    JOBOFFER_LIST_SUCCESS,
    JOBOFFER_LIST_FAIL,

    JOBOFFER_ADD_REQUEST,
    JOBOFFER_ADD_SUCCESS,
    JOBOFFER_ADD_FAIL,
    JOBOFFER_ADD_RESET,

    JOBOFFER_DELETE_REQUEST,
    JOBOFFER_DELETE_SUCCESS,
    JOBOFFER_DELETE_FAIL,
    JOBOFFER_DELETE_RESET,

    JOBOFFER_UPDATE_REQUEST,
    JOBOFFER_UPDATE_SUCCESS,
    JOBOFFER_UPDATE_FAIL,
    JOBOFFER_UPDATE_RESET,
} from '../constants/jobOfferConstants';

// Action to list all job offers
export const listJobOffers = () => async (dispatch, getState) => {
    try {
        dispatch({ type: JOBOFFER_LIST_REQUEST });

        const { data } = await axios.get(`/api/jobOffers/get_job_offers/`);

        dispatch({
            type: JOBOFFER_LIST_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: JOBOFFER_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Action to add a new job offer
export const addJobOffer = (jobOffer) => async (dispatch, getState) => {
    try {
        dispatch({ type: JOBOFFER_ADD_REQUEST });

        const { userLogin: { userInfo } } = getState();

        jobOffer.append('createur', userInfo.id);
        
        const instance = axios.create({
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(`/api/jobOffers/add_job_offer/`, jobOffer);

        dispatch({
            type: JOBOFFER_ADD_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: JOBOFFER_ADD_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Action to delete a job offer
export const deleteJobOffer = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: JOBOFFER_DELETE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`/api/jobOffers/delete_job_offer/${id}/`, config);

        dispatch({
            type: JOBOFFER_DELETE_SUCCESS,
        });

    } catch (error) {
        dispatch({
            type: JOBOFFER_DELETE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Action to update a job offer
export const updateJobOffer = (jobOfferId, jobOffer) => async (dispatch, getState) => {
    try {
        dispatch({ type: JOBOFFER_UPDATE_REQUEST });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(`/api/jobOffers/update_job_offer/${jobOfferId}/`, jobOffer, config);

        dispatch({
            type: JOBOFFER_UPDATE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: JOBOFFER_UPDATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Reset actions
export const addJobOfferReset = () => (dispatch) => {
    dispatch({ type: JOBOFFER_ADD_RESET });
};

export const deleteJobOfferReset = () => (dispatch) => {
    dispatch({ type: JOBOFFER_DELETE_RESET });
};

export const updateJobOfferReset = () => (dispatch) => {
    dispatch({ type: JOBOFFER_UPDATE_RESET });
};
