import React, { useState, useEffect } from 'react';
import './EtreAverti.css';
import { isValidEmail } from '../../../utils/emailUtils';
import { ActionButton } from '../../Button2/ActionButton/ActionButton';

const EtreAverti = ({ isOpen, onClose, onSubmit, success, disposableEmails }) => {
    // Initialisez les états pour les champs du formulaire
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');


    // Utiliser useEffect pour réinitialiser le formulaire après un succès
    useEffect(() => {
        if (success) {
            // Réinitialiser les champs du formulaire après un envoi réussi
            setEmail('');
            setError('');
        }
    }, [success]);

    if (!isOpen) return null;

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValidEmail(email)) {
            setError('Veuillez entrer une adresse email valide.');
            return;
        }
        
        setError(''); // Réinitialise l'erreur si l'email est valide
        onSubmit({ email });
    };

    return (
        <div className="etre-averti-overlay">
            <div className="etre-averti-content">
                <h2>S'inscrire pour être averti</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Email :
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </label>
                    <div className="etre-averti-buttons">
                        <span onClick={onClose}>
                            <ActionButton
                            text="Annuler"
                            style="outlined"/>
                        </span>
                        <span onClick={handleSubmit}>
                            <ActionButton
                                style="filled"
                                text="Envoyer"
                                type="submit"
                            />
                        </span>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EtreAverti;
