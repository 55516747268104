import {
    PARTENAIRE_LIST_REQUEST,
    PARTENAIRE_LIST_SUCCESS,
    PARTENAIRE_LIST_FAIL,

    PARTENAIRE_ADD_REQUEST,
    PARTENAIRE_ADD_SUCCESS,
    PARTENAIRE_ADD_FAIL,
    PARTENAIRE_ADD_RESET,

    PARTENAIRE_DELETE_REQUEST,
    PARTENAIRE_DELETE_SUCCESS,
    PARTENAIRE_DELETE_FAIL,
    PARTENAIRE_DELETE_RESET,

    PARTENAIRE_UPDATE_REQUEST,
    PARTENAIRE_UPDATE_SUCCESS,
    PARTENAIRE_UPDATE_FAIL,
    PARTENAIRE_UPDATE_RESET
} from '../constants/partenaireConstants';

import axios from 'axios';

export const partenaireListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: PARTENAIRE_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/partenaire/get_partenaires/`,
            config
        )

        dispatch({
            type: PARTENAIRE_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PARTENAIRE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const partenaireAddAction = (partenaire) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PARTENAIRE_ADD_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(
            `/api/partenaire/add_partenaire/`,
            partenaire,
            config
        )

        dispatch({
            type: PARTENAIRE_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PARTENAIRE_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const partenaireDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PARTENAIRE_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/partenaire/delete_partenaire/${id}/`,
            config
        )

        dispatch({
            type: PARTENAIRE_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PARTENAIRE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const partenaireUpdateAction = (partenaire_id, partenaire) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PARTENAIRE_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        console.log(partenaire_id, partenaire)
   
        const { data } = await axios.put(
            `/api/partenaire/update_partenaire/${partenaire_id}/`,
            partenaire,
            config
        )

        dispatch({
            type: PARTENAIRE_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PARTENAIRE_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const partenaireAddResetAction = () => (dispatch) => {
    dispatch({ type: PARTENAIRE_ADD_RESET });
}

export const partenaireDeleteResetAction = () => (dispatch) => {
    dispatch({ type: PARTENAIRE_DELETE_RESET });
}

export const partenaireUpdateResetAction = () => (dispatch) => {
    dispatch({ type: PARTENAIRE_UPDATE_RESET });
}