export const PARTENAIRE_LIST_REQUEST = 'PARTENAIRE_LIST_REQUEST';
export const PARTENAIRE_LIST_SUCCESS = 'PARTENAIRE_LIST_SUCCESS';
export const PARTENAIRE_LIST_FAIL = 'PARTENAIRE_LIST_FAIL';

export const PARTENAIRE_ADD_REQUEST = 'PARTENAIRE_ADD_REQUEST';
export const PARTENAIRE_ADD_SUCCESS = 'PARTENAIRE_ADD_SUCCESS';
export const PARTENAIRE_ADD_FAIL = 'PARTENAIRE_ADD_FAIL';
export const PARTENAIRE_ADD_RESET = 'PARTENAIRE_ADD_RESET';

export const PARTENAIRE_DELETE_REQUEST = 'PARTENAIRE_DELETE_REQUEST';
export const PARTENAIRE_DELETE_SUCCESS = 'PARTENAIRE_DELETE_SUCCESS';
export const PARTENAIRE_DELETE_FAIL = 'PARTENAIRE_DELETE_FAIL';
export const PARTENAIRE_DELETE_RESET = 'PARTENAIRE_DELETE_RESET';

export const PARTENAIRE_UPDATE_REQUEST = 'PARTENAIRE_UPDATE_REQUEST';
export const PARTENAIRE_UPDATE_SUCCESS = 'PARTENAIRE_UPDATE_SUCCESS';
export const PARTENAIRE_UPDATE_FAIL = 'PARTENAIRE_UPDATE_FAIL';
export const PARTENAIRE_UPDATE_RESET = 'PARTENAIRE_UPDATE_RESET';
