import {
    TEAM_MEMBER_LIST_REQUEST,
    TEAM_MEMBER_LIST_SUCCESS,
    TEAM_MEMBER_LIST_FAIL,

    TEAM_MEMBER_ADD_REQUEST,
    TEAM_MEMBER_ADD_SUCCESS,
    TEAM_MEMBER_ADD_FAIL,
    TEAM_MEMBER_ADD_RESET,

    TEAM_MEMBER_DELETE_REQUEST,
    TEAM_MEMBER_DELETE_SUCCESS,
    TEAM_MEMBER_DELETE_FAIL,
    TEAM_MEMBER_DELETE_RESET,

    TEAM_MEMBER_UPDATE_REQUEST,
    TEAM_MEMBER_UPDATE_SUCCESS,
    TEAM_MEMBER_UPDATE_FAIL,
    TEAM_MEMBER_UPDATE_RESET,

    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAIL
} from '../constants/teamMemberConstants';

// Reducer pour lister les membres de l'équipe
export const teamMemberListReducer = (state = { teamMembers: [] }, action) => {
    switch (action.type) {
        case TEAM_MEMBER_LIST_REQUEST:
            return { loading: true, teamMembers: [] };
        case TEAM_MEMBER_LIST_SUCCESS:
            return { loading: false, teamMembers: action.payload };
        case TEAM_MEMBER_LIST_FAIL:
            return { loading: false, error: action.payload };
        case UPDATE_ORDER_REQUEST:
            return { ...state, loading: true };
        case UPDATE_ORDER_SUCCESS:
            return { ...state, loading: false };
        case UPDATE_ORDER_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// Reducer pour ajouter un membre de l'équipe
export const teamMemberAddReducer = (state = {}, action) => {
    switch (action.type) {
        case TEAM_MEMBER_ADD_REQUEST:
            return { loading: true };
        case TEAM_MEMBER_ADD_SUCCESS:
            return { loading: false, success: true, teamMember: action.payload };
        case TEAM_MEMBER_ADD_FAIL:
            return { loading: false, error: action.payload };
        case TEAM_MEMBER_ADD_RESET:
            return {};
        default:
            return state;
    }
};

// Reducer pour supprimer un membre de l'équipe
export const teamMemberDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TEAM_MEMBER_DELETE_REQUEST:
            return { loading: true };
        case TEAM_MEMBER_DELETE_SUCCESS:
            return { loading: false, success: true };
        case TEAM_MEMBER_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case TEAM_MEMBER_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

// Reducer pour mettre à jour un membre de l'équipe
export const teamMemberUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case TEAM_MEMBER_UPDATE_REQUEST:
            return { loading: true };
        case TEAM_MEMBER_UPDATE_SUCCESS:
            return { loading: false, success: true, teamMember: action.payload };
        case TEAM_MEMBER_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case TEAM_MEMBER_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};
