import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import des styles

const RichTextEditor = ({ value, onChange }) => {
  const [editorHtml, setEditorHtml] = useState(value);

  useEffect(() => {
    setEditorHtml(value);
  }, [value]); // Mettre à jour l'état interne lorsque la prop `value` change

  const handleChange = (html) => {
    setEditorHtml(html);
    onChange(html); // Propager le changement vers le parent
  };

  return (
    <ReactQuill 
      value={editorHtml}
      onChange={handleChange}
      modules={RichTextEditor.modules}
      formats={RichTextEditor.formats}
      bounds={'.app'}
      placeholder="Commencez à écrire..."
    />
  );
};

// Modules pour personnaliser la barre d'outils
RichTextEditor.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ],
};

RichTextEditor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

export default RichTextEditor;
