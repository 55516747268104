import {
    CONTACT_LIST_REQUEST,
    CONTACT_LIST_SUCCESS,
    CONTACT_LIST_FAIL,

    CONTACT_ADD_REQUEST,
    CONTACT_ADD_SUCCESS,
    CONTACT_ADD_FAIL,
    CONTACT_ADD_RESET,

    CONTACT_DELETE_REQUEST,
    CONTACT_DELETE_SUCCESS,
    CONTACT_DELETE_FAIL,
    CONTACT_DELETE_RESET,

    CONTACT_UPDATE_REQUEST,
    CONTACT_UPDATE_SUCCESS,
    CONTACT_UPDATE_FAIL,
    CONTACT_UPDATE_RESET,

    OBJET_CONTACT_LIST_REQUEST,
    OBJET_CONTACT_LIST_SUCCESS,
    OBJET_CONTACT_LIST_FAIL,

    OBJET_CONTACT_ADD_REQUEST,
    OBJET_CONTACT_ADD_SUCCESS,
    OBJET_CONTACT_ADD_FAIL,
    OBJET_CONTACT_ADD_RESET,

    OBJET_CONTACT_DELETE_REQUEST,
    OBJET_CONTACT_DELETE_SUCCESS,
    OBJET_CONTACT_DELETE_FAIL,
    OBJET_CONTACT_DELETE_RESET,

    OBJET_CONTACT_UPDATE_REQUEST,
    OBJET_CONTACT_UPDATE_SUCCESS,
    OBJET_CONTACT_UPDATE_FAIL,
    OBJET_CONTACT_UPDATE_RESET
} from '../constants/contactConstants';

import axios from 'axios';

// ========== GESTION DES CONTACTS ==========

// Action pour lister les contacts
export const contactListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_LIST_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.get(
            `/api/contact/get_contacts/`,
            config
        );

        dispatch({
            type: CONTACT_LIST_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: CONTACT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
};

// Action pour ajouter un contact
export const contactAddAction = (contact) => async (dispatch) => {
    try {
        dispatch({
            type: CONTACT_ADD_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json', // Envoi de données au format JSON
            },
        };

        // Envoie les données de contact directement
        const { data } = await axios.post(
            `/api/contact/add_contact/`,
            contact, // Envoi l'objet contact
            config
        );

        dispatch({
            type: CONTACT_ADD_SUCCESS,
            payload: data,
        });

    } catch (error) {
        console.error("Erreur lors de l'ajout du contact", error); 
        dispatch({
            type: CONTACT_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
};

// Action pour supprimer un contact
export const contactDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_DELETE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.delete(
            `/api/contact/delete_contact/${id}/`,
            config
        );

        dispatch({
            type: CONTACT_DELETE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: CONTACT_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
};

// Action pour mettre à jour un contact
export const contactUpdateAction = (contact_id, contact) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_UPDATE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(
            `/api/contact/update_contact/${contact_id}/`,
            contact,
            config
        );

        dispatch({
            type: CONTACT_UPDATE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: CONTACT_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
};

// Action pour réinitialiser l'état d'ajout de contact
export const contactAddResetAction = () => (dispatch) => {
    dispatch({ type: CONTACT_ADD_RESET });
}

// Action pour réinitialiser l'état de suppression de contact
export const contactDeleteResetAction = () => (dispatch) => {
    dispatch({ type: CONTACT_DELETE_RESET });
}

// Action pour réinitialiser l'état de mise à jour de contact
export const contactUpdateResetAction = () => (dispatch) => {
    dispatch({ type: CONTACT_UPDATE_RESET });
}

// ========== GESTION DES OBJETCONTACT ==========

// Action pour lister les objets de contact
export const objetContactListAction = () => async (dispatch) => {
    try {
        dispatch({
            type: OBJET_CONTACT_LIST_REQUEST,
        });

        const { data } = await axios.get(`/api/contact/objetcontact/`);

        dispatch({
            type: OBJET_CONTACT_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: OBJET_CONTACT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

// Action pour ajouter un objet de contact
export const objetContactAddAction = (objetContact) => async (dispatch) => {
    try {
        dispatch({
            type: OBJET_CONTACT_ADD_REQUEST,
        });

        const { data } = await axios.post(`/api/contact/add_objetcontact/`, objetContact);

        dispatch({
            type: OBJET_CONTACT_ADD_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: OBJET_CONTACT_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

// Action pour supprimer un objet de contact
export const objetContactDeleteAction = (id) => async (dispatch) => {
    try {
        dispatch({
            type: OBJET_CONTACT_DELETE_REQUEST,
        });

        await axios.delete(`/api/contact/objetcontact/delete/${id}/`);

        dispatch({
            type: OBJET_CONTACT_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: OBJET_CONTACT_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

// Action pour mettre à jour un objet de contact
export const objetContactUpdateAction = (id, objetContact) => async (dispatch) => {
    try {
        dispatch({
            type: OBJET_CONTACT_UPDATE_REQUEST,
        });

        const { data } = await axios.put(`/api/contact/objetcontact/update/${id}/`, objetContact);

        dispatch({
            type: OBJET_CONTACT_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: OBJET_CONTACT_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

// Actions pour réinitialiser les états de success/fail pour ObjetContact
export const objetContactAddResetAction = () => (dispatch) => {
    dispatch({ type: OBJET_CONTACT_ADD_RESET });
};

export const objetContactDeleteResetAction = () => (dispatch) => {
    dispatch({ type: OBJET_CONTACT_DELETE_RESET });
};

export const objetContactUpdateResetAction = () => (dispatch) => {
    dispatch({ type: OBJET_CONTACT_UPDATE_RESET });
};
