import {
    OFFRE_EMPLOI_LIST_REQUEST,
    OFFRE_EMPLOI_LIST_SUCCESS,
    OFFRE_EMPLOI_LIST_FAIL,

    OFFRE_EMPLOI_ADD_REQUEST,
    OFFRE_EMPLOI_ADD_SUCCESS,
    OFFRE_EMPLOI_ADD_FAIL,
    OFFRE_EMPLOI_ADD_RESET,

    OFFRE_EMPLOI_DELETE_REQUEST,
    OFFRE_EMPLOI_DELETE_SUCCESS,
    OFFRE_EMPLOI_DELETE_FAIL,
    OFFRE_EMPLOI_DELETE_RESET,

    OFFRE_EMPLOI_UPDATE_REQUEST,
    OFFRE_EMPLOI_UPDATE_SUCCESS,
    OFFRE_EMPLOI_UPDATE_FAIL,
    OFFRE_EMPLOI_UPDATE_RESET
} from '../constants/offreEmploiConstants';

import axios from 'axios';

export const offreEmploiListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: OFFRE_EMPLOI_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/offre-emploi/get_offreEmplois/`,
            config
        )

        dispatch({
            type: OFFRE_EMPLOI_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: OFFRE_EMPLOI_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const offreEmploiAddAction = (offreEmploi) => async (dispatch, getState) => {
    try {
        dispatch({
            type: OFFRE_EMPLOI_ADD_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.post(
            `/api/offre-emploi/add_offreEmploi/`,
            offreEmploi,
            config
        )

        dispatch({
            type: OFFRE_EMPLOI_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: OFFRE_EMPLOI_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const offreEmploiDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: OFFRE_EMPLOI_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/offre-emploi/delete_offreEmploi/${id}/`,
            config
        )

        dispatch({
            type: OFFRE_EMPLOI_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: OFFRE_EMPLOI_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const offreEmploiUpdateAction = (offreEmploi_id, offreEmploi) => async (dispatch, getState) => {
    try {
        dispatch({
            type: OFFRE_EMPLOI_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        
        const { data } = await axios.put(
            `/api/offre-emploi/update_offreEmploi/${offreEmploi_id}/`,
            offreEmploi,
            config
        )

        dispatch({
            type: OFFRE_EMPLOI_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: OFFRE_EMPLOI_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const offreEmploiAddResetAction = () => (dispatch) => {
    dispatch({ type: OFFRE_EMPLOI_ADD_RESET });
}

export const offreEmploiDeleteResetAction = () => (dispatch) => {
    dispatch({ type: OFFRE_EMPLOI_DELETE_RESET });
}

export const offreEmploiUpdateResetAction = () => (dispatch) => {
    dispatch({ type: OFFRE_EMPLOI_UPDATE_RESET });
}