import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';
import "react-toggle/style.css";

import {
    informationListAction,
    informationAddAction,
    informationUpdateAction,
    informationDeleteAction,
    informationAddResetAction,
    informationUpdateResetAction,
    informationDeleteResetAction
} from '../../redux/actions/informationActions';
import Modale from '../../components/Modale/Modale';
import Projet from './Projet/Projet';
import Rejoindre from './Rejoindre/Rejoindre';
import Accueil from './Accueil/Accueil';
import Propos from "./Propos/Propos";
import Contact from "./Contact/AdminObjetContact/AdminObjetContact";

import './Administration.css';


function Administration() {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('accueil');
    const [modaleState, setModaleState] = useState({isOpen: false, type: '', message: ''});

    useEffect(() => {
        dispatch(informationListAction());
    }, [dispatch]);

    const renderAccueil = () => {
        return (
            <div className="administration-accueil">
                <div className="sub-tab-content">
                    <Accueil/>
                </div>
            </div>
        );
    }

    const renderPropos = () => {
        return (
            <div className="administration-coodent">
                <div className="sub-tab-content">
                    <Propos/>
                </div>
            </div>
        );
    };

    const renderProjet = () => {
        return (
            <div className="administration-projets">
                <div className="sub-tab-content">
                    <Projet/>
                </div>
            </div>
        );
    };

    const renderRejoindre = () => {
        return (
            <div className="administration-rejoindre">
                <div className="sub-tab-content">
                    <Rejoindre/>
                </div>
            </div>
        );
    };

    const renderContact = () => {
        return (
            <div className="administration-contact">
                <div className="sub-tab-content">
                    <Contact/>
                </div>
            </div>
        );
    };

    const handleCloseModale = () => {
        setModaleState({...modaleState, isOpen: false});
    };

    return (
        <div className="administration">
            <Helmet>
                <title>Administration</title>
            </Helmet>
            <div className="tabs">
                <button className={activeTab === 'accueil' ? 'bar-active' : 'bar-normal'}
                        onClick={() => setActiveTab('accueil')}>Accueil
                </button>
                <button className={activeTab === 'propos' ? 'bar-active' : 'bar-normal'}
                        onClick={() => setActiveTab('propos')}>A Propos
                </button>
                <button className={activeTab === 'projet' ? 'bar-active' : 'bar-normal'}
                        onClick={() => setActiveTab('projet')}>Nos Projets
                </button>
                <button className={activeTab === 'nous-rejoindre' ? 'bar-active' : 'bar-normal'}
                        onClick={() => setActiveTab('nous-rejoindre')}>Nous rejoindre
                </button>
                <button className={activeTab === 'contact' ? 'bar-active' : 'bar-normal'}
                        onClick={() => setActiveTab('contact')}>Contact
                </button>
            </div>
            <div className="tab-content">
                {activeTab === 'accueil' && renderAccueil()}
                {activeTab === 'propos' && renderPropos()}
                {activeTab === 'projet' && renderProjet()}
                {activeTab === 'nous-rejoindre' && renderRejoindre()}
                {activeTab === 'contact' && renderContact()}
            </div>

            <Modale
                isOpen={modaleState.isOpen}
                onClose={handleCloseModale}
                title={modaleState.type === 'success' ? 'Succès' : 'Erreur'}
                type={modaleState.type}
            >
                <p>{modaleState.message}</p>
            </Modale>
        </div>
    );
}

export default Administration;
