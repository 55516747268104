import {
    PROJET_LIST_REQUEST,
    PROJET_LIST_SUCCESS,
    PROJET_LIST_FAIL,

    PROJET_ADD_REQUEST,
    PROJET_ADD_SUCCESS,
    PROJET_ADD_FAIL,
    PROJET_ADD_RESET,

    PROJET_DELETE_REQUEST,
    PROJET_DELETE_SUCCESS,
    PROJET_DELETE_FAIL,
    PROJET_DELETE_RESET,

    PROJET_UPDATE_REQUEST,
    PROJET_UPDATE_SUCCESS,
    PROJET_UPDATE_FAIL,
    PROJET_UPDATE_RESET
} from '../constants/projetConstants';

import axios from 'axios';

export const projetListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROJET_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/projet/get_projets/`,
            config
        )

        dispatch({
            type: PROJET_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PROJET_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const projetAddAction = (projet) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROJET_ADD_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(
            `/api/projet/add_projet/`,
            projet,
            config
        )

        dispatch({
            type: PROJET_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PROJET_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const projetDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROJET_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/projet/delete_projet/${id}/`,
            config
        )

        dispatch({
            type: PROJET_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PROJET_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const projetUpdateAction = (projet_id, projet) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROJET_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        
        const { data } = await axios.put(
            `/api/projet/update_projet/${projet_id}/`,
            projet,
            config
        )

        dispatch({
            type: PROJET_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PROJET_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const projetAddResetAction = () => (dispatch) => {
    dispatch({ type: PROJET_ADD_RESET });
}

export const projetDeleteResetAction = () => (dispatch) => {
    dispatch({ type: PROJET_DELETE_RESET });
}

export const projetUpdateResetAction = () => (dispatch) => {
    dispatch({ type: PROJET_UPDATE_RESET });
}