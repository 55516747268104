import {
    DISPOSABLE_EMAIL_LIST_REQUEST,
    DISPOSABLE_EMAIL_LIST_SUCCESS,
    DISPOSABLE_EMAIL_LIST_FAIL,

    DISPOSABLE_EMAIL_ADD_REQUEST,
    DISPOSABLE_EMAIL_ADD_SUCCESS,
    DISPOSABLE_EMAIL_ADD_FAIL,
    DISPOSABLE_EMAIL_ADD_RESET,

    DISPOSABLE_EMAIL_DELETE_REQUEST,
    DISPOSABLE_EMAIL_DELETE_SUCCESS,
    DISPOSABLE_EMAIL_DELETE_FAIL,
    DISPOSABLE_EMAIL_DELETE_RESET,

    DISPOSABLE_EMAIL_UPDATE_REQUEST,
    DISPOSABLE_EMAIL_UPDATE_SUCCESS,
    DISPOSABLE_EMAIL_UPDATE_FAIL,
    DISPOSABLE_EMAIL_UPDATE_RESET,
} from '../constants/emailJetableConstants';

export const disposableEmailListReducer = (state = { disposableEmails: [] }, action) => {
    switch (action.type) {
        case DISPOSABLE_EMAIL_LIST_REQUEST:
            return { loading: true, disposableEmails: [] };
        case DISPOSABLE_EMAIL_LIST_SUCCESS:
            return { loading: false, disposableEmails: action.payload };
        case DISPOSABLE_EMAIL_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const disposableEmailAddReducer = (state = {}, action) => {
    switch (action.type) {
        case DISPOSABLE_EMAIL_ADD_REQUEST:
            return { loading: true };
        case DISPOSABLE_EMAIL_ADD_SUCCESS:
            return { loading: false, success: true, disposableEmail: action.payload };
        case DISPOSABLE_EMAIL_ADD_FAIL:
            return { loading: false, error: action.payload };
        case DISPOSABLE_EMAIL_ADD_RESET:
            return {};
        default:
            return state;
    }
}

export const disposableEmailDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case DISPOSABLE_EMAIL_DELETE_REQUEST:
            return { loading: true };
        case DISPOSABLE_EMAIL_DELETE_SUCCESS:
            return { loading: false, success: true };
        case DISPOSABLE_EMAIL_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case DISPOSABLE_EMAIL_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

export const disposableEmailUpdateReducer = (state = { disposableEmail: {} }, action) => {
    switch (action.type) {
        case DISPOSABLE_EMAIL_UPDATE_REQUEST:
            return { loading: true };
        case DISPOSABLE_EMAIL_UPDATE_SUCCESS:
            return { loading: false, success: true, disposableEmail: action.payload };
        case DISPOSABLE_EMAIL_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case DISPOSABLE_EMAIL_UPDATE_RESET:
            return { disposableEmail: {} };
        default:
            return state;
    }
}