/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const PlaceholderImage3 = ({ className }) => {
  return (
    <svg
      className={`placeholder-image-3 ${className}`}
      fill="none"
      height="190"
      viewBox="0 0 543 190"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill="#EFF2F6" height="190" rx="24" width="543" />
      <path
        className="path"
        d="M319.5 132.333V57.6667C319.5 51.8 314.7 47 308.833 47H234.167C228.3 47 223.5 51.8 223.5 57.6667V132.333C223.5 138.2 228.3 143 234.167 143H308.833C314.7 143 319.5 138.2 319.5 132.333ZM252.833 103L266.167 119.053L284.833 95L308.833 127H234.167L252.833 103Z"
        fill="#9CB0C9"
      />
    </svg>
  );
};
