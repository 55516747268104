import React, { useEffect, useRef, useState } from "react";
import {
    EditorState,
    RichUtils,
    Editor,
    AtomicBlockUtils,
    convertToRaw,
    convertFromRaw,
    convertFromHTML,
    ContentState
} from "draft-js";

import Toolbar from "./Toolbar/Toolbar";
import { stateToHTML } from 'draft-js-export-html'; // Import du convertisseur en HTML

import "./ArticleEditor.css";

const insertImage = (editorState, url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
        "IMAGE",
        "IMMUTABLE",
        { src: url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        " "
    );
    return newEditorState;
};

const ArticleEditor = ({ exporter = false, supprimer=false, onExport, onDelete, content = '', exportHtml =false }) => {
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            convertFromRaw({
                blocks: [
                    {
                        key: "3eesq",
                        text: content,
                        type: "unstyled",
                        depth: 0,
                        inlineStyleRanges: [
                            {
                                offset: 19,
                                length: 6,
                                style: "BOLD",
                            },
                            {
                                offset: 25,
                                length: 5,
                                style: "ITALIC",
                            },
                            {
                                offset: 30,
                                length: 8,
                                style: "UNDERLINE",
                            },
                        ],
                        entityRanges: [],
                        data: {},
                    }
                ],
                entityMap: {},
            })
        )
    );
    const editor = useRef(null);

    useEffect(() => {
        focusEditor();
    }, []);

    useEffect(() => {
        if(exportHtml) {
            exportToHTML()
        }
    }, [exportHtml]);

    useEffect(() => {
        if (content) {
            const blocksFromHTML = convertFromHTML(content);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            setEditorState(EditorState.createWithContent(state));
        } else {
            setEditorState(EditorState.createEmpty());
        }
    }, [content]);


    const focusEditor = () => {
        editor.current.focus();
    };

    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return true;
        }
        return false;
    };

    const addImage = (url) => {
        const newEditorState = insertImage(editorState, url);
        setEditorState(newEditorState);
    };

    const mediaBlockRenderer = (block) => {
        if (block.getType() === "atomic") {
            return {
                component: Media,
                editable: false,
            };
        }
        return null;
    };

    // FOR INLINE STYLES
    const styleMap = {
        CODE: {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
            fontSize: 16,
            padding: 2,
        },
        HIGHLIGHT: {
            backgroundColor: "#F7A5F7",
        },
        UPPERCASE: {
            textTransform: "uppercase",
        },
        LOWERCASE: {
            textTransform: "lowercase",
        },
        CODEBLOCK: {
            fontFamily: '"fira-code", "monospace"',
            fontSize: "inherit",
            background: "#ffeff0",
            fontStyle: "italic",
            lineHeight: 1.5,
            padding: "0.3rem 0.5rem",
            borderRadius: " 0.2rem",
        },
        SUPERSCRIPT: {
            verticalAlign: "super",
            fontSize: "80%",
        },
        SUBSCRIPT: {
            verticalAlign: "sub",
            fontSize: "80%",
        },
    };

    // FOR BLOCK LEVEL STYLES(Returns CSS Class From ArticleEditor.css)
    const myBlockStyleFn = (contentBlock) => {
        const type = contentBlock.getType();
        switch (type) {
            case "blockQuote":
                return "superFancyBlockquote";
            case "leftAlign":
                return "leftAlign";
            case "rightAlign":
                return "rightAlign";
            case "centerAlign":
                return "centerAlign";
            case "justifyAlign":
                return "justifyAlign";
            case "header-two":
                return "custom-h2";
            case "header-three":
                return "custom-h3";
            default:
                break;
        }
    };

    // Fonction pour exporter en HTML
    const exportToHTML = () => {
        const contentState = editorState.getCurrentContent();
        const html = stateToHTML(contentState);
        console.log(html); // Stocke ou utilise cette chaîne HTML comme tu le souhaites
        if (onExport) {
            onExport(html); // Appelle la fonction onExport avec le HTML
        }
    };

    // Fonction pour supprimer l'article
    const deleteArticle = () => {
        setEditorState(EditorState.createEmpty());
        if (onDelete) {
            onDelete(); // Appelle la fonction onExport avec une chaîne vide
        }
    };

    return (
        <div className="editor-wrapper" onClick={focusEditor}>
            <Toolbar
                editorState={editorState}
                setEditorState={setEditorState}
                addImage={addImage} // Passe la fonction addImage
            />
            <div className="editor-container">
                <Editor
                    ref={editor}
                    placeholder="Write Here"
                    handleKeyCommand={handleKeyCommand}
                    editorState={editorState}
                    customStyleMap={styleMap}
                    blockStyleFn={myBlockStyleFn}
                    blockRendererFn={mediaBlockRenderer}
                    onChange={(editorState) => {
                        const contentState = editorState.getCurrentContent();
                        // console.log(convertToRaw(contentState));
                        setEditorState(editorState);

                    }}
                />
            </div>
            {/* Bouton pour exporter en HTML */}
            {exporter &&
                <div className="article-editor-button">
                    <button className='export-html' onClick={exportToHTML}>Sauvegarder</button>
                    {supprimer && <button className='suppresion-article' onClick={deleteArticle}>Supprimer l'article</button>}
                </div>
            }
        </div>
    );
};

const Media = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src } = entity.getData();
    const type = entity.getType();

    if (type === "IMAGE") {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={src} alt="Uploaded" style={{ maxWidth: '100%', height: 'auto', display: 'block' }} />
            </div>
        );
    }

    return null;
};

export default ArticleEditor;
