// Constants for listing parametres
export const PARAMETRES_LIST_REQUEST = 'PARAMETRES_LIST_REQUEST';
export const PARAMETRES_LIST_SUCCESS = 'PARAMETRES_LIST_SUCCESS';
export const PARAMETRES_LIST_FAIL = 'PARAMETRES_LIST_FAIL';

// Constants for adding a new parametre
export const PARAMETRES_ADD_REQUEST = 'PARAMETRES_ADD_REQUEST';
export const PARAMETRES_ADD_SUCCESS = 'PARAMETRES_ADD_SUCCESS';
export const PARAMETRES_ADD_FAIL = 'PARAMETRES_ADD_FAIL';
export const PARAMETRES_ADD_RESET = 'PARAMETRES_ADD_RESET';

// Constants for deleting a parametre
export const PARAMETRES_DELETE_REQUEST = 'PARAMETRES_DELETE_REQUEST';
export const PARAMETRES_DELETE_SUCCESS = 'PARAMETRES_DELETE_SUCCESS';
export const PARAMETRES_DELETE_FAIL = 'PARAMETRES_DELETE_FAIL';
export const PARAMETRES_DELETE_RESET = 'PARAMETRES_DELETE_RESET';

// Constants for updating a parametre
export const PARAMETRES_UPDATE_REQUEST = 'PARAMETRES_UPDATE_REQUEST';
export const PARAMETRES_UPDATE_SUCCESS = 'PARAMETRES_UPDATE_SUCCESS';
export const PARAMETRES_UPDATE_FAIL = 'PARAMETRES_UPDATE_FAIL';
export const PARAMETRES_UPDATE_RESET = 'PARAMETRES_UPDATE_RESET';
