// src/redux/slices/etreAvertiSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getCSRFToken } from '../../utils/csrf';

// Exemple d'API pour envoyer les données du formulaire
export const addAvertissement = createAsyncThunk(
  'etreAverti/addAvertissement',
  async (data, { rejectWithValue }) => {
    try {
        const csrfToken = getCSRFToken(); // Récupérer le jeton CSRF
      const response = await axios.post('/api/averti/add_avertir/', data, {
        headers: {
          'X-CSRFToken': csrfToken, // Ajouter le jeton CSRF dans l'en-tête
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue('Une erreur s\'est produite lors de la soumission de votre demande. Veuillez réessayer.');
    }
  }
);

const etreAvertiSlice = createSlice({
  name: 'etreAverti',
  initialState: {
    isOpen: false,
    loading: false,
    error: null,
    successMessage: null,
  },
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAvertissement.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null; // Reset messages on new request
      })
      .addCase(addAvertissement.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.message || 'Votre demande a été soumise avec succès! Nous vous contacterons sous peu.';
        state.isOpen = false;
      })
      .addCase(addAvertissement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Une erreur est survenue.';
      });
  },
});

export const { openModal, closeModal } = etreAvertiSlice.actions;

export default etreAvertiSlice.reducer;
