import {
    PARTENAIRE_LIST_REQUEST,
    PARTENAIRE_LIST_SUCCESS,
    PARTENAIRE_LIST_FAIL,

    PARTENAIRE_ADD_REQUEST,
    PARTENAIRE_ADD_SUCCESS,
    PARTENAIRE_ADD_FAIL,
    PARTENAIRE_ADD_RESET,

    PARTENAIRE_DELETE_REQUEST,
    PARTENAIRE_DELETE_SUCCESS,
    PARTENAIRE_DELETE_FAIL,
    PARTENAIRE_DELETE_RESET,

    PARTENAIRE_UPDATE_REQUEST,
    PARTENAIRE_UPDATE_SUCCESS,
    PARTENAIRE_UPDATE_FAIL,
    PARTENAIRE_UPDATE_RESET
} from '../constants/partenaireConstants';

// Compare this snippet from user-interface/src/redux/reducers/partenaireReducers.js:
export const partenaireListReducer = (state = { partenaires: [] }, action) => {
    switch (action.type) {
        case PARTENAIRE_LIST_REQUEST:
            return { loading: true, partenaires: [] };
        case PARTENAIRE_LIST_SUCCESS:
            return { loading: false, partenaires: action.payload };
        case PARTENAIRE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/partenaireReducers.js:
export const partenaireAddReducer = (state = {}, action) => {
    switch (action.type) {
        case PARTENAIRE_ADD_REQUEST:
            return { loading: true };
        case PARTENAIRE_ADD_SUCCESS:
            return { loading: false, success: true, partenaire: action.payload };
        case PARTENAIRE_ADD_FAIL:
            return { loading: false, error: action.payload };
        case PARTENAIRE_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/partenaireReducers.js:
export const partenaireDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PARTENAIRE_DELETE_REQUEST:
            return { loading: true };
        case PARTENAIRE_DELETE_SUCCESS:
            return { loading: false, success: true };
        case PARTENAIRE_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case PARTENAIRE_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/partenaireReducers.js:
export const partenaireUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case PARTENAIRE_UPDATE_REQUEST:
            return { loading: true };
        case PARTENAIRE_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case PARTENAIRE_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case PARTENAIRE_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
