import {
    ADD_AVERTISSEMENT_REQUEST,
    ADD_AVERTISSEMENT_SUCCESS,
    ADD_AVERTISSEMENT_FAIL,
    ADD_AVERTISSEMENT_RESET
} from '../constants/avertissementConstants';

import axios from 'axios';

export const addAvertissement = (email) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_AVERTISSEMENT_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.post(
            '/api/averti/add_avertir/',  
            {'email': email},
            config
        )

        dispatch({
            type: ADD_AVERTISSEMENT_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ADD_AVERTISSEMENT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const resetAvertissement = () => async (dispatch) => {
    dispatch({ type: ADD_AVERTISSEMENT_RESET });
}