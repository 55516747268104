import React, { useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom'

import { createMarkup } from '../../utils';

import './OffreEmploi.css'; // Assurez-vous d'avoir le bon chemin pour votre fichier CSS

function OffreEmploi({ offreEmploi }) {
    let navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDescription = () => {
        setIsOpen(!isOpen);
    };

    // Fonction pour tronquer la description
    const getTruncatedDescription = (description, maxLength = 250) => {
        if (description.length > maxLength && !isOpen) {
            return `${description.substring(0, maxLength)}... <span class="read-more">Lire la suite</span>`;
        }
        return description;
    };
    

    return (
        <div className={`offreEmploi-card ${isOpen ? 'open' : ''} ${offreEmploi.type}`} onClick={toggleDescription}>
            <h2>{offreEmploi.titre}</h2>
            <p className='offreEmploi-description' dangerouslySetInnerHTML={createMarkup(getTruncatedDescription(offreEmploi.description))}></p>
            {isOpen && (
                <div className="offreEmploi-button">
                    <button className="postuler-btn" onClick={() => navigate('/candidature', { state: { titre: offreEmploi.titre } })}>
                        Postuler
                    </button>
                </div>
            )}
        </div>
    );
}

export default OffreEmploi;
