import {
    PROJET_LIST_REQUEST,
    PROJET_LIST_SUCCESS,
    PROJET_LIST_FAIL,

    PROJET_ADD_REQUEST,
    PROJET_ADD_SUCCESS,
    PROJET_ADD_FAIL,
    PROJET_ADD_RESET,

    PROJET_DELETE_REQUEST,
    PROJET_DELETE_SUCCESS,
    PROJET_DELETE_FAIL,
    PROJET_DELETE_RESET,

    PROJET_UPDATE_REQUEST,
    PROJET_UPDATE_SUCCESS,
    PROJET_UPDATE_FAIL,
    PROJET_UPDATE_RESET
} from '../constants/projetConstants';

// Compare this snippet from user-interface/src/redux/reducers/projetReducers.js:
export const projetListReducer = (state = { projets: [] }, action) => {
    switch (action.type) {
        case PROJET_LIST_REQUEST:
            return { loading: true, projets: [] };
        case PROJET_LIST_SUCCESS:
            return { loading: false, projets: action.payload };
        case PROJET_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/projetReducers.js:
export const projetAddReducer = (state = {}, action) => {
    switch (action.type) {
        case PROJET_ADD_REQUEST:
            return { loading: true };
        case PROJET_ADD_SUCCESS:
            return { loading: false, success: true, projet: action.payload };
        case PROJET_ADD_FAIL:
            return { loading: false, error: action.payload };
        case PROJET_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/projetReducers.js:
export const projetDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PROJET_DELETE_REQUEST:
            return { loading: true };
        case PROJET_DELETE_SUCCESS:
            return { loading: false, success: true };
        case PROJET_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case PROJET_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/projetReducers.js:
export const projetUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case PROJET_UPDATE_REQUEST:
            return { loading: true };
        case PROJET_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case PROJET_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case PROJET_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
