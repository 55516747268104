import React, { useEffect, useState } from "react";
import './AnswerCandidate.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RichTextEditor from '../../../../components/RichTextEditor/RichTextEditor'; // Assurez-vous que le chemin d'accès est correct
import { StyleOutlinedWrapper } from "../../../../components/StyleOutlinedWrapper/StyleOutlinedWrapper";


function AnswerCandidate({ data }) {
    const [email, setEmail] = useState('');

    const handleEditorChange = (value) => {
        setEmail(value);
    };

    const sendEmail = () => {
        // Créer le lien mailto avec les informations dynamiques
        const mailtoLink = `mailto:${data.email}?subject=Réponse à votre candidature pour le poste de ${data.type_poste}&body=${encodeURIComponent(email)}`;
        
        // Ouvrir le lien mailto
        window.location.href = mailtoLink;
    };

    return (
        <div className="answer-candidate">
            <div>Répondre à {data.nom} {data.prenom}</div>
            <div className="margin">Pour le poste de {data.type_poste}</div>
            <div className="margin">
                <RichTextEditor className="answer-editor" value={email} onChange={handleEditorChange} />
            </div>
            <div className="pointer-span margin" onClick={sendEmail}>
                <StyleOutlinedWrapper className="button" style="filled" text="Envoyer" />
            </div>
        </div>
    );
}
export default AnswerCandidate;
