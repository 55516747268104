import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';


export const fetchProjets = createAsyncThunk('projets/fetchProjets', async (_, {getState, rejectWithValue}) => {
    try {
        const {userLogin: {userInfo}} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.get('/api/projet/get_projets_admin/', config);
        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || error.message);
    }
});

export const addProjet = createAsyncThunk('projets/addProjet', async (formData, {getState, rejectWithValue}) => {
    try {
        const {userLogin: {userInfo}} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.post('/api/projet/add_projet/', formData, config);
        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || error.message);
    }
});


export const updateProjet = createAsyncThunk('projets/updateProjet', async ({id, formData}, {
    getState,
    rejectWithValue
}) => {
    try {
        const {userLogin: {userInfo}} = getState(); // Récupération du token
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`, // Token JWT
            },
        };

        const {data} = await axios.put(`/api/projet/update_projet/${id}/`, formData, config);
        return data;
    } catch (error) {
        console.log ("Erreur")
        return rejectWithValue(error.response?.data?.message || error.message);
    }
});

export const deleteProjet = createAsyncThunk('projets/deleteProjet', async (id, {getState, rejectWithValue}) => {
    try {
        const {userLogin: {userInfo}} = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`/api/projet/delete_projet/${id}/`, config);
        return id; // retourne id du projet supprimé mais peut etre mettre le titre
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || error.message);
    }
});

export const toggleActiveProjet = createAsyncThunk('projets/toggleActiveProjet', async ({id}, {
    getState,
    rejectWithValue
}) => {
    try {
        const {userLogin: {userInfo}} = getState();

        console.log(userInfo.token)
        console.log(id)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.put(
            `/api/projet/active_projet/${id}/`,
            {},
            config
        );

        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || error.message);
    }
});

export const updateProjetsOrder = createAsyncThunk('projets/updateProjetsOrder', async (orderedProjets, { getState, rejectWithValue }) => {
    try {
        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
                'Content-Type': 'application/json',
            },
        };

        // Envoi de la liste ordonnée au serveur si besoin
        await axios.post('/api/projet/update_projet_order/', { projets: orderedProjets }, config);

        return orderedProjets; // Retourne la nouvelle liste ordonnée
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || error.message);
    }
});


// Slices pour gérer les projets

const projetSlice = createSlice({
    name: 'projets',
    initialState: {
        projets: [],
        loading: false,
        error: null,
        success: false,
        message: null,
    },
    reducers: {
        resetSuccess(state) {
            state.success = false;  // Pour réinitialiser l'état de succès après une action
            state.message = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // Gestion de la liste des projets
            .addCase(fetchProjets.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProjets.fulfilled, (state, action) => {
                state.loading = false;
                state.projets = action.payload;
            })
            .addCase(fetchProjets.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Gestion de l'ajout de projet
            .addCase(addProjet.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addProjet.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.projets.push(action.payload); // On ajoute le projet
            })
            .addCase(addProjet.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Gestion de la mise à jour de projet
            .addCase(updateProjet.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProjet.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.message = 'Le projet a été mis à jour avec succès.';
                const index = state.projets.findIndex(projet => projet.id === action.payload.id);
                if (index !== -1) {
                    state.projets[index] = action.payload; // On remplace le projet mis à jour
                }
            })
            .addCase(updateProjet.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.message = `Erreur lors de la mise à jour du projet : ${action.payload}`;
            })
            // Gestion de la suppression de projet
            .addCase(deleteProjet.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteProjet.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.message = 'Le projet a été supprimé avec succès.';
                state.projets = state.projets.filter(projet => projet.id !== action.payload);
            })
            .addCase(deleteProjet.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.message = `Erreur lors de la suppression du projet : ${action.payload}`;
            })
            // Gestion de l'activation de projet
            .addCase(toggleActiveProjet.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(toggleActiveProjet.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                const index = state.projets.findIndex(projet => projet.id === action.payload.id);
                if (index !== -1) {
                    state.projets[index] = action.payload;
                }
            })
            .addCase(toggleActiveProjet.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Gestion de la mise à jour de l'ordre des projets
            .addCase(updateProjetsOrder.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProjetsOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.projets = action.payload;  // Met à jour l'ordre des projets
                console.log("Ordre des projets mis à jour :", state.projets);
            })
            .addCase(updateProjetsOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const {resetSuccess} = projetSlice.actions;

export default projetSlice.reducer;
