import {
    INFORMATION_LIST_REQUEST,
    INFORMATION_LIST_SUCCESS,
    INFORMATION_LIST_FAIL,

    INFORMATION_ADD_REQUEST,
    INFORMATION_ADD_SUCCESS,
    INFORMATION_ADD_FAIL,
    INFORMATION_ADD_RESET,

    INFORMATION_DELETE_REQUEST,
    INFORMATION_DELETE_SUCCESS,
    INFORMATION_DELETE_FAIL,
    INFORMATION_DELETE_RESET,

    INFORMATION_UPDATE_REQUEST,
    INFORMATION_UPDATE_SUCCESS,
    INFORMATION_UPDATE_FAIL,
    INFORMATION_UPDATE_RESET
} from '../constants/informationConstants';

import axios from 'axios';

export const informationListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: INFORMATION_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/information/get_informations/`,
            config
        )

        dispatch({
            type: INFORMATION_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: INFORMATION_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const informationAddAction = (information) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INFORMATION_ADD_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(
            `/api/information/add_information/`,
            information,
            config
        )

        dispatch({
            type: INFORMATION_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: INFORMATION_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const informationDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INFORMATION_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/information/delete_information/${id}/`,
            config
        )

        dispatch({
            type: INFORMATION_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: INFORMATION_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const informationUpdateAction = (information_id, information) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INFORMATION_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        
        const { data } = await axios.put(
            `/api/information/update_information/${information_id}/`,
            information,
            config
        )

        dispatch({
            type: INFORMATION_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: INFORMATION_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const informationAddResetAction = () => (dispatch) => {
    dispatch({ type: INFORMATION_ADD_RESET });
}

export const informationDeleteResetAction = () => (dispatch) => {
    dispatch({ type: INFORMATION_DELETE_RESET });
}

export const informationUpdateResetAction = () => (dispatch) => {
    dispatch({ type: INFORMATION_UPDATE_RESET });
}