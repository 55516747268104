import React from 'react';
import './PageNotFound.css';

function PageNotFound() {
  return (
    <div className="page-not-found">
      <h1>404</h1>
      <p>Oops! La page que vous cherchez n'existe pas.</p>
      <a href="/">Retourner à l'accueil</a>
    </div>
  );
}

export default PageNotFound;
