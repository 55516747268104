import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { partenaireListAction } from '../../redux/actions/partenaireActions';
import Partenaire from '../../components/Partenaire/Partenaire';
import './Partenaires.css'; // Assurez-vous d'avoir le bon chemin pour votre fichier CSS

function Partenaires() {
    const dispatch = useDispatch();
    
    const partenaireList = useSelector((state) => state.partenaireList);
    const { partenaires, loading, error } = partenaireList;

    useEffect(() => {
        dispatch(partenaireListAction());
    }, []);

    return (
        <div className="partenaires-container">
            <Helmet>
                <title>Découvrez ceux qui nous font confiance et qui travaillent avec nous</title>
                <meta name="description" content="Partenaires" />
                <meta name="keywords" content="services, coode-nt, domisimpli, googe play store, app store" />
            </Helmet>
            {loading && <p>Chargement...</p>}
            {error && <p>Erreur : {error}</p>}
            {partenaires && partenaires.map(partenaire => (
                <Partenaire key={partenaire.id} partenaire={partenaire} />
            ))}
        </div>
    );
}

export default Partenaires;
