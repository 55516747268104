import {
    CANDIDATURE_EMPLOI_LIST_REQUEST,
    CANDIDATURE_EMPLOI_LIST_SUCCESS,
    CANDIDATURE_EMPLOI_LIST_FAIL,

    CANDIDATURE_EMPLOI_ADD_REQUEST,
    CANDIDATURE_EMPLOI_ADD_SUCCESS,
    CANDIDATURE_EMPLOI_ADD_FAIL,
    CANDIDATURE_EMPLOI_ADD_RESET,

    CANDIDATURE_EMPLOI_DELETE_REQUEST,
    CANDIDATURE_EMPLOI_DELETE_SUCCESS,
    CANDIDATURE_EMPLOI_DELETE_FAIL,
    CANDIDATURE_EMPLOI_DELETE_RESET,

    CANDIDATURE_EMPLOI_UPDATE_REQUEST,
    CANDIDATURE_EMPLOI_UPDATE_SUCCESS,
    CANDIDATURE_EMPLOI_UPDATE_FAIL,
    CANDIDATURE_EMPLOI_UPDATE_RESET
} from '../constants/candidatureEmploiConstants';

import axios from 'axios';

// Action pour récupérer la liste des candidatures
export const candidatureEmploiListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CANDIDATURE_EMPLOI_LIST_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.get(`/api/candidature_emploi/candidatures/`, config);

        dispatch({
            type: CANDIDATURE_EMPLOI_LIST_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: CANDIDATURE_EMPLOI_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

// Action pour ajouter une candidature avec documents multiples
export const candidatureEmploiAddAction = (candidature) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CANDIDATURE_EMPLOI_ADD_REQUEST,
        });

        const formDataToSubmit = new FormData();

        // Ajoutez les autres champs de formulaire
        for (const key in candidature) {
            if (candidature.hasOwnProperty(key) && key !== 'documents') {
                formDataToSubmit.append(key, candidature[key]);
            }
        }

        // Ajoutez les fichiers un par un
        if (candidature.documents && candidature.documents.length > 0) {
            Array.from(candidature.documents).forEach((file) => {
                formDataToSubmit.append('documents', file);  // Ajout de chaque fichier
            });
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        const { data } = await axios.post(`/api/candidature_emploi/candidatures/create/`, formDataToSubmit, config);

        dispatch({
            type: CANDIDATURE_EMPLOI_ADD_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: CANDIDATURE_EMPLOI_ADD_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

// Action pour supprimer une candidature
export const candidatureEmploiDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CANDIDATURE_EMPLOI_DELETE_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.delete(`/api/candidature_emploi/candidatures/delete/${id}/`, config);

        dispatch({
            type: CANDIDATURE_EMPLOI_DELETE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: CANDIDATURE_EMPLOI_DELETE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

// Action pour mettre à jour une candidature
export const candidatureEmploiUpdateAction = (candidature_id, candidature) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CANDIDATURE_EMPLOI_UPDATE_REQUEST,
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.put(`/api/candidature_emploi/candidatures/update/${candidature_id}/`, candidature, config);

        dispatch({
            type: CANDIDATURE_EMPLOI_UPDATE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: CANDIDATURE_EMPLOI_UPDATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
};

// Action pour réinitialiser l'état après une addition de candidature
export const candidatureEmploiAddResetAction = () => (dispatch) => {
    dispatch({ type: CANDIDATURE_EMPLOI_ADD_RESET });
};

// Action pour réinitialiser l'état après une suppression de candidature
export const candidatureEmploiDeleteResetAction = () => (dispatch) => {
    dispatch({ type: CANDIDATURE_EMPLOI_DELETE_RESET });
};

// Action pour réinitialiser l'état après une mise à jour de candidature
export const candidatureEmploiUpdateResetAction = () => (dispatch) => {
    dispatch({ type: CANDIDATURE_EMPLOI_UPDATE_RESET });
};
