import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'; // Import des icônes
import {
    objetContactAddAction,
    objetContactListAction,
    objetContactUpdateAction,
    objetContactDeleteAction,
} from '../../../../redux/actions/contactActions';

import "../AdminObjetContact/AdminObjetContact.css";

const AdminObjetContact = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ nom: '' });
    const [editData, setEditData] = useState(null);
    const [errors, setErrors] = useState({});

    const objetContactList = useSelector((state) => state.objetContactList);
    const { loading, error, objetContacts } = objetContactList;

    const objetContactAdd = useSelector((state) => state.objetContactAdd);
    const { success: successAdd } = objetContactAdd;

    const objetContactUpdate = useSelector((state) => state.objetContactUpdate);
    const { success: successUpdate } = objetContactUpdate;

    const objetContactDelete = useSelector((state) => state.objetContactDelete);
    const { success: successDelete } = objetContactDelete;

    useEffect(() => {
        dispatch(objetContactListAction());
    }, [dispatch, successAdd, successUpdate, successDelete]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.nom.trim()) {
            formErrors.nom = 'Le nom de l\'objet est requis.';
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            if (editData) {
                dispatch(objetContactUpdateAction(editData.id, formData));
            } else {
                dispatch(objetContactAddAction(formData));
            }
            setFormData({ nom: '' });
            setEditData(null);
        }
    };

    const handleEdit = (objet) => {
        setEditData(objet);
        setFormData({ nom: objet.nom });
    };

    const handleDelete = (id) => {
        if (window.confirm("Voulez-vous vraiment supprimer cet objet de contact ?")) {
            dispatch(objetContactDeleteAction(id));
        }
    };

    return (
        <div className="admin-objet-contact">
            <h1>Gestion des objets du formulaire de contact</h1>

            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="nom"
                    value={formData.nom}
                    onChange={handleChange}
                    placeholder="Nom de l'objet"
                    required
                />
                {errors.nom && <p className="error">{errors.nom}</p>}
                <button className="add" type="submit" disabled={loading}>
                    {editData ? 'Modifier' : 'Ajouter'}
                </button>
            </form>

            {loading && <p>Chargement des objets de contact...</p>}
            {error && <p className="error">{error}</p>}

            <h2>Liste des objets</h2>
            <ul>
                {objetContacts && objetContacts.map((objet) => (
                    <li key={objet.id}>
                        {objet.nom}
                        <div>
                            <button className="edit" onClick={() => handleEdit(objet)}>
                                <FontAwesomeIcon icon={faEdit} className="icon" />
                            </button>
                            <button className="trash" onClick={() => handleDelete(objet.id)}>
                                <FontAwesomeIcon icon={faTrash} className="icon" />
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdminObjetContact;

