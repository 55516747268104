export const JOBOFFER_LIST_REQUEST = 'JOBOFFER_LIST_REQUEST';
export const JOBOFFER_LIST_SUCCESS = 'JOBOFFER_LIST_SUCCESS';
export const JOBOFFER_LIST_FAIL = 'JOBOFFER_LIST_FAIL';

export const JOBOFFER_ADD_REQUEST = 'JOBOFFER_ADD_REQUEST';
export const JOBOFFER_ADD_SUCCESS = 'JOBOFFER_ADD_SUCCESS';
export const JOBOFFER_ADD_FAIL = 'JOBOFFER_ADD_FAIL';
export const JOBOFFER_ADD_RESET = 'JOBOFFER_ADD_RESET';

export const JOBOFFER_DELETE_REQUEST = 'JOBOFFER_DELETE_REQUEST';
export const JOBOFFER_DELETE_SUCCESS = 'JOBOFFER_DELETE_SUCCESS';
export const JOBOFFER_DELETE_FAIL = 'JOBOFFER_DELETE_FAIL';
export const JOBOFFER_DELETE_RESET = 'JOBOFFER_DELETE_RESET';

export const JOBOFFER_UPDATE_REQUEST = 'JOBOFFER_UPDATE_REQUEST';
export const JOBOFFER_UPDATE_SUCCESS = 'JOBOFFER_UPDATE_SUCCESS';
export const JOBOFFER_UPDATE_FAIL = 'JOBOFFER_UPDATE_FAIL';
export const JOBOFFER_UPDATE_RESET = 'JOBOFFER_UPDATE_RESET';
