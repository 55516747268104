import {
    JOBOFFER_LIST_REQUEST,
    JOBOFFER_LIST_SUCCESS,
    JOBOFFER_LIST_FAIL,

    JOBOFFER_ADD_REQUEST,
    JOBOFFER_ADD_SUCCESS,
    JOBOFFER_ADD_FAIL,
    JOBOFFER_ADD_RESET,

    JOBOFFER_DELETE_REQUEST,
    JOBOFFER_DELETE_SUCCESS,
    JOBOFFER_DELETE_FAIL,
    JOBOFFER_DELETE_RESET,

    JOBOFFER_UPDATE_REQUEST,
    JOBOFFER_UPDATE_SUCCESS,
    JOBOFFER_UPDATE_FAIL,
    JOBOFFER_UPDATE_RESET
} from '../constants/jobOfferConstants';

export const jobOfferListReducer = (state = { jobOffers: [] }, action) => {
    switch (action.type) {
        case JOBOFFER_LIST_REQUEST:
            return { loading: true, jobOffers: [] };
        case JOBOFFER_LIST_SUCCESS:
            return { loading: false, jobOffers: action.payload };
        case JOBOFFER_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const jobOfferAddReducer = (state = {}, action) => {
    switch (action.type) {
        case JOBOFFER_ADD_REQUEST:
            return { loading: true };
        case JOBOFFER_ADD_SUCCESS:
            return { loading: false, success: true, jobOffer: action.payload };
        case JOBOFFER_ADD_FAIL:
            return { loading: false, error: action.payload };
        case JOBOFFER_ADD_RESET:
            return {};
        default:
            return state;
    }
};

export const jobOfferDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case JOBOFFER_DELETE_REQUEST:
            return { loading: true };
        case JOBOFFER_DELETE_SUCCESS:
            return { loading: false, success: true };
        case JOBOFFER_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case JOBOFFER_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

export const jobOfferUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case JOBOFFER_UPDATE_REQUEST:
            return { loading: true };
        case JOBOFFER_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case JOBOFFER_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case JOBOFFER_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};
