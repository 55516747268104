import PropTypes from "prop-types";
import React, { useState } from "react";
import "./ActionButton.css";

export const ActionButton = ({ style, className, text = "Do something", divClassName, type }) => {
    // États pour savoir si le bouton est survolé ou cliqué
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    // Fonctions pour gérer l'entrée et la sortie du survol
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setIsClicked(false); // Réinitialiser l'état cliqué si la souris quitte le bouton
    };

    // Fonctions pour gérer le clic
    const handleMouseDown = () => {
        setIsClicked(true);
    };

    const handleMouseUp = () => {
        setIsClicked(false);
    };

    // Détermine quelle classe utiliser (remplace filled par outlined et vice versa au survol)
    const buttonStyleClass = isHovered
        ? style === "filled" ? "style-0-outlined" : "style-0-filled"
        : `style-0-${style}`;

    // Classe dynamique ajoutée si le bouton est cliqué
    const clickClass = isClicked ? "clicked" : "";

    // Classes combinées pour le bouton
    const buttonClassName = `style-outlined-wrapper ${buttonStyleClass} ${clickClass} ${className}`;
    const divClassNameFinal = `do-something-2 ${divClassName}`;

    return (
        <button
            type={type}
            className={buttonClassName}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            <div className={divClassNameFinal}>{text}</div>
        </button>
    );
};

ActionButton.propTypes = {
    style: PropTypes.oneOf(["filled", "outlined"]),
    text: PropTypes.string,
    type: PropTypes.string,
};
