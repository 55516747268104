// Constants pour la liste des candidatures
export const CANDIDATURE_EMPLOI_LIST_REQUEST = 'CANDIDATURE_EMPLOI_LIST_REQUEST';
export const CANDIDATURE_EMPLOI_LIST_SUCCESS = 'CANDIDATURE_EMPLOI_LIST_SUCCESS';
export const CANDIDATURE_EMPLOI_LIST_FAIL = 'CANDIDATURE_EMPLOI_LIST_FAIL';

// Constants pour l'ajout d'une candidature
export const CANDIDATURE_EMPLOI_ADD_REQUEST = 'CANDIDATURE_EMPLOI_ADD_REQUEST';
export const CANDIDATURE_EMPLOI_ADD_SUCCESS = 'CANDIDATURE_EMPLOI_ADD_SUCCESS';
export const CANDIDATURE_EMPLOI_ADD_FAIL = 'CANDIDATURE_EMPLOI_ADD_FAIL';
export const CANDIDATURE_EMPLOI_ADD_RESET = 'CANDIDATURE_EMPLOI_ADD_RESET';

// Constants pour la suppression d'une candidature
export const CANDIDATURE_EMPLOI_DELETE_REQUEST = 'CANDIDATURE_EMPLOI_DELETE_REQUEST';
export const CANDIDATURE_EMPLOI_DELETE_SUCCESS = 'CANDIDATURE_EMPLOI_DELETE_SUCCESS';
export const CANDIDATURE_EMPLOI_DELETE_FAIL = 'CANDIDATURE_EMPLOI_DELETE_FAIL';
export const CANDIDATURE_EMPLOI_DELETE_RESET = 'CANDIDATURE_EMPLOI_DELETE_RESET';

// Constants pour la mise à jour d'une candidature
export const CANDIDATURE_EMPLOI_UPDATE_REQUEST = 'CANDIDATURE_EMPLOI_UPDATE_REQUEST';
export const CANDIDATURE_EMPLOI_UPDATE_SUCCESS = 'CANDIDATURE_EMPLOI_UPDATE_SUCCESS';
export const CANDIDATURE_EMPLOI_UPDATE_FAIL = 'CANDIDATURE_EMPLOI_UPDATE_FAIL';
export const CANDIDATURE_EMPLOI_UPDATE_RESET = 'CANDIDATURE_EMPLOI_UPDATE_RESET';
