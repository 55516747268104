import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlaceholderImage1 } from "../../icons/PlaceholderPropos/PlaceholderImage1";
import { PlaceholderImage3 } from "../../icons/PlaceholderPropos/PlaceholderImage3";
import { PlaceholderImage4 } from "../../icons/PlaceholderPropos/PlaceholderImage4";
import TeamMember from "../../components/TeamMember/TeamMember.js";
import { teamMemberListAction } from "../../redux/actions/teamMembersActions";
import "./Propos.css";


export const Propos = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  // Utilisation de Redux pour récupérer les membres de l'équipe
  const teamMemberList = useSelector((state) => state.teamMemberList);
  const { teamMembers, loading, error } = teamMemberList;

  // Filtrage pour n'afficher que les membres actifs
  const activeTeamMembers = teamMembers && teamMembers.filter(member => member.is_active);

  // Détecte si on est en version mobile ou desktop en fonction de la largeur de la fenêtre
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Définir 768px comme seuil mobile
    };

    // Ecoute la taille de la fenêtre dès le montage
    handleResize();
    window.addEventListener("resize", handleResize);

    // Nettoyage de l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    // Dispatch de l'action pour obtenir les membres de l'équipe
    dispatch(teamMemberListAction());
  }, [dispatch]);

  return (
    <div className="propos">
      {/* Section Découvrez Coode-NT */}
      <div className="auto-layout-2">
        {isMobile ? (
          <img src='https://img.freepik.com/photos-gratuite/jetee-au-bord-lac-hallstatt-autriche_181624-44201.jpg?ga=GA1.1.132570296.1725958073&semt=ais_hybrid-rr-similar'
            alt='paysage'
            className='discov-img'
          />
        ) : (
          <PlaceholderImage1 className="icon-instance-node" />
        )}
        <div className="auto-layout-vertical">
          <div className="auto-layout-vertical-2">
              <h1 className="text-wrapper-5">Découvrez Coode-NT</h1>
            </div>
            <p className="text-wrapper-6 coodent">
              COODE-NT se distingue par ses valeurs de partage, écoute et innovation. Nous collaborons étroitement avec
              vous pour développer des solutions sur mesure, en prenant soin de comprendre vos besoins et en partageant
              nos expertises. Chaque projet est guidé par l’innovation et un accompagnement personnalisé, garantissant
              des résultats alignés avec vos attentes et les tendances du marché. Chez COODE-NT, nous valorisons les
              talents et l’originalité, pour repousser ensemble les limites du possible.
            </p>
        </div>
      </div>

      {/* Section Nos Valeurs */}
      <div className="content-section">
        <div className="text-wrapper-7">Nos valeurs</div>

        {/* Valeur 1: Le Partage */}
        <div className="auto-layout-3 partage">
          <PlaceholderImage3 className="icon-instance-node" />
          <div className="auto-layout-vertical">
            <div className="text-wrapper-8">Le partage</div>
            <p className="text-wrapper-6">
              Le partage est au cœur de notre démarche. Nous travaillons en étroite collaboration avec nos clients,
              partageant nos connaissances et idées pour enrichir chaque projet. Grâce à une transparence totale, nous
              développons des solutions innovantes, alignées avec les tendances du marché et les besoins des
              utilisateurs finaux.
            </p>
          </div>
        </div>

        {/* Valeur 2: L’Écoute */}
        <div className="auto-layout-3 ecoute">
          <div className="auto-layout-vertical">
            <div className="text-wrapper-8">L’écoute</div>
            <p className="text-wrapper-6">
              L’écoute est essentielle. Nous prenons le temps de comprendre en profondeur vos besoins et attentes avant
              de commencer la conception. Cette approche nous permet de créer des solutions sur mesure parfaitement
              alignées avec les objectifs de votre entreprise.
            </p>
          </div>
          <PlaceholderImage3 className="icon-instance-node-2" />
        </div>

        {/* Valeur 3: Encouragement des Talents */}
        <div className="auto-layout-3 encouragement">
          <PlaceholderImage4 className="icon-instance-node" />
          <div className="auto-layout-vertical encouragement">
            <p className="title-wrapper-8">Encouragement des Talents et Management Participatif</p>
            <p className="text-wrapper-6">
              Nous valorisons l’innovation et le talent à travers une culture de management participatif. Chaque membre
              de l’équipe est encouragé à proposer des idées audacieuses et à contribuer activement, garantissant des
              solutions à la fois techniquement avancées et créatives.
            </p>
          </div>
        </div>

        {/* Valeur 4: L’Accompagnement */}
        <div className="auto-layout-3 accompagnement">
          <div className="auto-layout-vertical">
            <div className="text-wrapper-8">L’accompagnement</div>
            <p className="text-wrapper-6">
              L’accompagnement va au-delà de l’assistance technique. Nous vous soutenons à chaque étape, de la
              conception à l’évolution de votre application, en vous offrant conseils et guidance pour maximiser votre
              succès. Notre objectif est de vous fournir une solution exceptionnelle tout en vous permettant de tirer
              pleinement parti de chaque opportunité.
            </p>
          </div>
          <PlaceholderImage3 className="icon-instance-node-2" />
        </div>
      </div>

      {/* Section Vidéo */}
      <div className="content-section-2">
        <div className="auto-layout-vertical-3">
          <div className="video-text-wrapper-7">Titre video</div>
          <div className="video-text-wrapper-6">Texte video</div>
        </div>
        {isMobile ? (
          <>
            <img src='https://img.freepik.com/photos-gratuite/clapperboard-popcorn-bobines_23-2147807376.jpg?t=st=1728993662~exp=1728997262~hmac=89529fac7213e582473bb784341ef3fc46dd91a8fea58dcea93a898a8a637eba&w=740'
              alt='video'
              className='video-img'
              />
          </>
        ) : (
          <PlaceholderImage3 className="icon-instance-node" />)}
      </div>

      {/* Section Notre Équipe */}
      <div className="content-section-3">
        <div className="auto-layout-vertical-4">
          <div className="text-wrapper-9">Notre Équipe</div>
          <p className="p">
            Chaque membre apporte une expertise unique et une passion pour l’innovation, contribuant ainsi à la réussite
            de nos projets. Découvrez bientôt les talents qui façonnent nos solutions et découvrons ensemble comment
            leur expertise peut transformer vos idées en réalité. Restez connectés pour en savoir plus sur ceux qui
            rendent tout cela possible !
          </p>
        </div>
        <div className="auto-layout-4">
          {loading ? (
            <p>Chargement des membres de l'équipe...</p>
          ) : error ? (
            <p>Aucun membre de l'équipe trouvé {console.log('Erreur lors de la récupération des membres : ',error)}</p>
          ) : (
            isMobile ? (
                activeTeamMembers.map((membre, index) => (
                  <div key={index} >
                    <TeamMember name={membre.name} title={membre.title} image={membre.image_url} />
                  </div>
                ))
            ) : (
              // Affichage normal sur desktop
              activeTeamMembers && activeTeamMembers.length > 0 ? (
                activeTeamMembers.map((membre, index) => (
                  <TeamMember key={index} name={membre.name} title={membre.title} image={membre.image_url} />
                ))
              ) : (
                <p>Aucun membre de l'équipe trouvé</p>
              )
            )
          )}
        </div>
      </div>
    </div>
  );
};