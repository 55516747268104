import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import { createMarkup } from '../../utils';

import { offreEmploiListAction } from '../../redux/actions/offreEmploiActions';
import OffreEmploi from '../../components/OffreEmploi/OffreEmploi';
import './OffresEmplois.css'; // Assurez-vous d'avoir le bon chemin pour votre fichier CSS

function OffresEmplois() {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [text, setText] = useState('');

    const offreEmploiList = useSelector((state) => state.offreEmploiList);
    const { offreEmplois, loading, error } = offreEmploiList;

    useEffect(() => {
        dispatch(offreEmploiListAction());

        fetch('media/textes/offreEmploi.txt')
            .then((response) => response.text())
            .then((text) => {
                setText(text);
            });
    }, []);

    const scrollToOffreEmploi = () => {
        const section = document.getElementById('offreEmploiSection');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className="offreEmploi-main-container">
            <Helmet>
                <title>Découvrez les offres d'emplois que propose la société COODE-NT</title>
                <meta name="description" content=">Offres d'emploi" />
                <meta name="keywords" content="services, coode-nt, domisimpli, googe play store, app store, offre d'emploi, candidature" />
            </Helmet>
            {loading && <p>Chargement...</p>}
            {error && <p>Erreur : {error}</p>}
            <div className="offreEmploi-description-container">
                <p className='offreEmploi-text' dangerouslySetInnerHTML={createMarkup(text)}></p>
                <div className="offreEmploi-btn-container">
                    <button className="candidature-spontane-btn" onClick={() => navigate('/candidature', { state: { spontane: true } })}>
                        Candidature spontanée
                    </button>
                    <button className="candidature-spontane-btn" onClick={scrollToOffreEmploi}>
                        Voir nos offres <FontAwesomeIcon icon={faArrowDown} />
                    </button>

                </div>
            </div>

            <div className="offreEmploi-container" id="offreEmploiSection">
                {offreEmplois && offreEmplois.map(offreEmploi => (
                    <OffreEmploi key={offreEmploi.id} offreEmploi={offreEmploi} />
                ))}
            </div>
        </div>
    );
}

export default OffresEmplois;
