import {
    TEAM_MEMBER_LIST_REQUEST,
    TEAM_MEMBER_LIST_SUCCESS,
    TEAM_MEMBER_LIST_FAIL,

    TEAM_MEMBER_ADD_REQUEST,
    TEAM_MEMBER_ADD_SUCCESS,
    TEAM_MEMBER_ADD_FAIL,
    TEAM_MEMBER_ADD_RESET,

    TEAM_MEMBER_DELETE_REQUEST,
    TEAM_MEMBER_DELETE_SUCCESS,
    TEAM_MEMBER_DELETE_FAIL,
    TEAM_MEMBER_DELETE_RESET,

    TEAM_MEMBER_UPDATE_REQUEST,
    TEAM_MEMBER_UPDATE_SUCCESS,
    TEAM_MEMBER_UPDATE_FAIL,
    TEAM_MEMBER_UPDATE_RESET, UPDATE_ORDER_REQUEST, UPDATE_ORDER_FAIL, UPDATE_ORDER_SUCCESS
} from '../constants/teamMemberConstants';

import axios from 'axios';

// Action pour récupérer la liste des membres de l'équipe
export const teamMemberListAction = () => async (dispatch) => {
    try {
        dispatch({ type: TEAM_MEMBER_LIST_REQUEST });

        const { data } = await axios.get(`/api/team/team/`);

        dispatch({
            type: TEAM_MEMBER_LIST_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: TEAM_MEMBER_LIST_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Action pour ajouter un membre de l'équipe
export const teamMemberAddAction = (teamMember) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEAM_MEMBER_ADD_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        const { data } = await axios.post(`/api/team/create/`, teamMember, config);

        dispatch({
            type: TEAM_MEMBER_ADD_SUCCESS,
            payload: data,
        });

        // Met à jour immédiatement la liste des membres dans Redux
        const currentMembers = getState().teamMemberList.teamMembers;
        dispatch({
            type: TEAM_MEMBER_LIST_SUCCESS,
            payload: [...currentMembers, data],
        });

    } catch (error) {
        dispatch({
            type: TEAM_MEMBER_ADD_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Action pour supprimer un membre de l'équipe
export const teamMemberDeleteAction = (teamMemberId) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEAM_MEMBER_DELETE_REQUEST });

        await axios.delete(`/api/team/delete/${teamMemberId}/`);

        dispatch({
            type: TEAM_MEMBER_DELETE_SUCCESS,
        });

        // Met à jour la liste des membres après suppression
        const currentMembers = getState().teamMemberList.teamMembers;
        dispatch({
            type: TEAM_MEMBER_LIST_SUCCESS,
            payload: currentMembers.filter((member) => member.id !== teamMemberId),
        });

    } catch (error) {
        dispatch({
            type: TEAM_MEMBER_DELETE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

// Action pour mettre à jour un membre de l'équipe
export const teamMemberUpdateAction = (teamMemberId, teamMember) => async (dispatch, getState) => {
    try {
        dispatch({ type: TEAM_MEMBER_UPDATE_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.put(`/api/team/update/${teamMemberId}/`, teamMember, config);

        dispatch({
            type: TEAM_MEMBER_UPDATE_SUCCESS,
            payload: data,
        });

        // Met à jour la liste des membres après modification
        const currentMembers = getState().teamMemberList.teamMembers;
        dispatch({
            type: TEAM_MEMBER_LIST_SUCCESS,
            payload: currentMembers.map((member) =>
                member.id === teamMemberId ? data : member
            ),
        });

    } catch (error) {
        dispatch({
            type: TEAM_MEMBER_UPDATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

export const updateOrderAction = (updatedMembers) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_ORDER_REQUEST }); // Début de la requête

        await axios.post(`/api/team/update-order/`, updatedMembers);

        dispatch({ type: UPDATE_ORDER_SUCCESS }); // Mise à jour réussie
    } catch (error) {
        dispatch({
            type: UPDATE_ORDER_FAIL, // Échec de la mise à jour
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};




// Action pour réinitialiser l'état après une addition de membre de l'équipe
export const teamMemberAddResetAction = () => (dispatch) => {
    dispatch({ type: TEAM_MEMBER_ADD_RESET });
};

// Action pour réinitialiser l'état après une suppression de membre de l'équipe
export const teamMemberDeleteResetAction = () => (dispatch) => {
    dispatch({ type: TEAM_MEMBER_DELETE_RESET });
};

// Action pour réinitialiser l'état après une mise à jour de membre de l'équipe
export const teamMemberUpdateResetAction = () => (dispatch) => {
    dispatch({ type: TEAM_MEMBER_UPDATE_RESET });
};
