import { useEffect, useState } from "react";

import { useDispatch, useSelector } from 'react-redux';
import Modale from '../../components/Modale/Modale';
import { contactAddAction, contactAddResetAction, objetContactListAction } from '../../redux/actions/contactActions';
import { listDisposableEmails } from '../../redux/actions/emailJetableActions';
import { isValidEmail, isDisposableEmail } from '../../utils/emailUtils';
import "../Contact/Contact.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { ActionButton } from "../../components/Button2/ActionButton/ActionButton.jsx";
import phone from '../../assets/phone.svg';
import mail from '../../assets/mail.svg';
import address from '../../assets/address.svg';


const Contact = () => {
    const dispatch = useDispatch();
    const objetContactList = useSelector((state) => state.objetContactList);
    const { objetContacts } = objetContactList;

    const disposableEmailList = useSelector((state) => state.disposableEmailList);
    const { disposableEmails } = disposableEmailList;

    // État du formulaire
    const [formData, setFormData] = useState({
        objet_contact: '',
        prenom: '',
        nom: '',
        email: '',
        telephone: '',
        message: '',
    });

    const [errors, setErrors] = useState({});
    const [modaleState, setModaleState] = useState({ isOpen: false, type: '', message: '' });

    const successMessage = 'Votre demande a été soumise avec succès! Nous vous contacterons sous peu.';
    const errorMessage = 'Une erreur s\'est produite lors de la soumission de votre demande. Veuillez réessayer.';

    const contactAdd = useSelector((state) => state.contactAdd);
    const { error, success } = contactAdd;

    useEffect(() => {
        dispatch(listDisposableEmails());
        dispatch(objetContactListAction())
    }, [dispatch]);



    useEffect(() => {
        if (success) {
            setModaleState({ isOpen: true, type: 'add-success', message: successMessage });
            // Réinitialise le formulaire après un ajout réussi
            setFormData({
                objet_contact: '',
                prenom: '',
                nom: '',
                email: '',
                telephone: '',
                message: '',
            });
        } else if (error) {
            setModaleState({ isOpen: true, type: 'add-error', message: errorMessage });
        }
        dispatch(contactAddResetAction());
    }, [success, error, dispatch, successMessage, errorMessage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let formErrors = {};
        const phoneRegex = /^[0-9]{10}$/;

        if (!formData.objet_contact) {
            formErrors.objet_contact = 'Veuillez sélectionner un objet.';
        }
        if (!formData.prenom.trim()) {
            formErrors.prenom = 'Le prénom est requis.';
        }
        if (!formData.nom.trim()) {
            formErrors.nom = 'Le nom est requis.';
        }
        if (!formData.email) {
            formErrors.email = "L'email est requis";
        } else if (!isValidEmail(formData.email)) {
            formErrors.email = "Veuillez entrer une adresse email valide.";
        } else if (isDisposableEmail(formData.email, disposableEmails.map((email) => email.domain_name))) {
            formErrors.email = "Les emails jetables ne sont pas autorisés";
        }
        if (!formData.telephone || !phoneRegex.test(formData.telephone)) {
            formErrors.telephone = 'Veuillez entrer un numéro de téléphone valide (10 chiffres).';
        }
        if (!formData.message || formData.message.length < 10) {
            formErrors.message = 'Le message doit contenir au moins 10 caractères.';
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            dispatch(contactAddAction(formData));
        } else {
            console.log('Erreur dans le formulaire', errors);
        }
    };

    const handleCloseModale = () => {
        setModaleState({ ...modaleState, isOpen: false });
    };

    return (
        <div className="contact">
            <div className="content-section">
                <div className="frame-2">
                    <div className="auto-layout-vertical">
                        <div className="text-wrapper-5">Contactez-nous</div>
                        <p className="partagez-nous-vos-id">
                            Partagez nous vos idées de projets !<br />
                            <br />
                            Nous prenons le temps de lire chaque message.
                        </p>
                    </div>

                    {/* Section Email, Téléphone, Adresse */}
                    <div className="frame-3">
                        <div className="frame-4">
                            <img className="rectangle" alt="mail" src={mail} />
                            <div className="text-wrapper-6">Email</div>
                        </div>
                        <div className="frame-4">
                            <img className="rectangle" alt="phone" src={phone} />
                            <div className="text-wrapper-6">Téléphone</div>
                        </div>
                        <div className="frame-4">
                            <img className="rectangle" alt="phone" src={address} />
                            <div className="text-wrapper-6">Adresse</div>
                        </div>
                    </div>
                </div>

                {/* Formulaire */}
                <div className="frame-5">
                    {/* Sélection de l'objet */}
                    <div className="div-5">
                        <div className="text-wrapper-7">Objet</div>
                        <div className="field">
                            <select name="objet_contact" value={formData.objet_contact} onChange={handleChange}
                                required>
                                <option value="">-- Sélectionnez --</option>
                                {objetContacts.map((objet) => (
                                    <option key={objet.id} value={objet.id}>{objet.nom}</option>
                                ))}
                            </select>
                            <FontAwesomeIcon icon={faAngleDown} className="select-icon" />
                            <div className="spacer" />
                        </div>
                        <p className="error">{errors.objet_contact && <p>{errors.objet_contact}</p>}</p>
                    </div>

                    {/* Prénom et Nom */}
                    <div className="div-5">
                        <div className="text-wrapper-6">Prénom</div>
                        <div className="input-field">
                            <input type="text" name="prenom" value={formData.prenom} onChange={handleChange}
                                placeholder="Prénom" required />
                        </div>
                        <div>{errors.prenom && <p className="error">{errors.prenom}</p>}</div>
                    </div>
                    <div className="div-5">
                        <div className="text-wrapper-6">Nom</div>
                        <div className="input-field">
                            <input type="text" name="nom" value={formData.nom} onChange={handleChange} placeholder="Nom"
                                required />
                        </div>
                        <div>{errors.nom && <p className="error">{errors.nom}</p>}</div>
                    </div>

                    {/* Email */}
                    <div className="div-5">
                        <div className="text-wrapper-6">E-mail</div>
                        <div className="field">
                            <input
                                className="exemple-gmail-com"
                                placeholder="exemple@gmail.com"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <div className="spacer" />
                        </div>
                        <div>{errors.email && <p className="error">{errors.email}</p>}</div>
                    </div>

                    {/* Numéro de téléphone */}
                    <div className="div-5">
                        <div className="text-wrapper-6">Numéro de téléphone</div>
                        <div className="field">
                            <input type="tel" name="telephone" value={formData.telephone} placeholder="07 00 00 00 00"
                                onChange={handleChange} required />
                            <div className="spacer" />
                        </div>
                        <div>{errors.telephone && <p className="error">{errors.telephone}</p>}</div>
                    </div>

                    {/* Message */}
                    <div className="div-5">
                        <div className="text-wrapper-6">Message</div>
                        <div className="field-3">
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="4"
                                placeholder="Message"
                                required
                            />
                        </div>
                        <div>{errors.message && <p className="error">{errors.message}</p>}</div>
                    </div>
                    <span onClick={handleSubmit}>
                        <ActionButton
                            style="filled"
                            text="Envoyer"
                            type="submit"
                        />
                    </span>
                </div>
            </div>

            {/* Modale */}
            <Modale
                isOpen={modaleState.isOpen}
                onClose={handleCloseModale}
                title={modaleState.type === 'add-success' ? 'Succès' : 'Erreur'}
                type={modaleState.type}
            >
                <p>{modaleState.message}</p>
            </Modale>
        </div>
    );
};

export default Contact;