import {
    CANDIDATURE_EMPLOI_LIST_REQUEST,
    CANDIDATURE_EMPLOI_LIST_SUCCESS,
    CANDIDATURE_EMPLOI_LIST_FAIL,

    CANDIDATURE_EMPLOI_ADD_REQUEST,
    CANDIDATURE_EMPLOI_ADD_SUCCESS,
    CANDIDATURE_EMPLOI_ADD_FAIL,
    CANDIDATURE_EMPLOI_ADD_RESET,

    CANDIDATURE_EMPLOI_DELETE_REQUEST,
    CANDIDATURE_EMPLOI_DELETE_SUCCESS,
    CANDIDATURE_EMPLOI_DELETE_FAIL,
    CANDIDATURE_EMPLOI_DELETE_RESET,

    CANDIDATURE_EMPLOI_UPDATE_REQUEST,
    CANDIDATURE_EMPLOI_UPDATE_SUCCESS,
    CANDIDATURE_EMPLOI_UPDATE_FAIL,
    CANDIDATURE_EMPLOI_UPDATE_RESET
} from '../constants/candidatureEmploiConstants';

// Reducer pour lister les candidatures d'emploi
export const candidatureEmploiListReducer = (state = { candidatures: [] }, action) => {
    switch (action.type) {
        case CANDIDATURE_EMPLOI_LIST_REQUEST:
            return { loading: true, candidatures: [] };
        case CANDIDATURE_EMPLOI_LIST_SUCCESS:
            return { loading: false, candidatures: action.payload };
        case CANDIDATURE_EMPLOI_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Reducer pour ajouter une candidature d'emploi
export const candidatureEmploiAddReducer = (state = {}, action) => {
    switch (action.type) {
        case CANDIDATURE_EMPLOI_ADD_REQUEST:
            return { loading: true };
        case CANDIDATURE_EMPLOI_ADD_SUCCESS:
            return { loading: false, success: true, candidature: action.payload };
        case CANDIDATURE_EMPLOI_ADD_FAIL:
            return { loading: false, error: action.payload };
        case CANDIDATURE_EMPLOI_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Reducer pour supprimer une candidature d'emploi
export const candidatureEmploiDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CANDIDATURE_EMPLOI_DELETE_REQUEST:
            return { loading: true };
        case CANDIDATURE_EMPLOI_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CANDIDATURE_EMPLOI_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case CANDIDATURE_EMPLOI_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Reducer pour mettre à jour une candidature d'emploi
export const candidatureEmploiUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CANDIDATURE_EMPLOI_UPDATE_REQUEST:
            return { loading: true };
        case CANDIDATURE_EMPLOI_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case CANDIDATURE_EMPLOI_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case CANDIDATURE_EMPLOI_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
