// ========== CONSTANTES POUR CONTACT ==========

export const CONTACT_LIST_REQUEST = 'CONTACT_LIST_REQUEST';
export const CONTACT_LIST_SUCCESS = 'CONTACT_LIST_SUCCESS';
export const CONTACT_LIST_FAIL = 'CONTACT_LIST_FAIL';

export const CONTACT_ADD_REQUEST = 'CONTACT_ADD_REQUEST';
export const CONTACT_ADD_SUCCESS = 'CONTACT_ADD_SUCCESS';
export const CONTACT_ADD_FAIL = 'CONTACT_ADD_FAIL';
export const CONTACT_ADD_RESET = 'CONTACT_ADD_RESET';

export const CONTACT_DELETE_REQUEST = 'CONTACT_DELETE_REQUEST';
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS';
export const CONTACT_DELETE_FAIL = 'CONTACT_DELETE_FAIL';
export const CONTACT_DELETE_RESET = 'CONTACT_DELETE_RESET';

export const CONTACT_UPDATE_REQUEST = 'CONTACT_UPDATE_REQUEST';
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS';
export const CONTACT_UPDATE_FAIL = 'CONTACT_UPDATE_FAIL';
export const CONTACT_UPDATE_RESET = 'CONTACT_UPDATE_RESET';

// ========== CONSTANTES POUR OBJETCONTACT ==========

export const OBJET_CONTACT_LIST_REQUEST = 'OBJET_CONTACT_LIST_REQUEST';
export const OBJET_CONTACT_LIST_SUCCESS = 'OBJET_CONTACT_LIST_SUCCESS';
export const OBJET_CONTACT_LIST_FAIL = 'OBJET_CONTACT_LIST_FAIL';

export const OBJET_CONTACT_ADD_REQUEST = 'OBJET_CONTACT_ADD_REQUEST';
export const OBJET_CONTACT_ADD_SUCCESS = 'OBJET_CONTACT_ADD_SUCCESS';
export const OBJET_CONTACT_ADD_FAIL = 'OBJET_CONTACT_ADD_FAIL';
export const OBJET_CONTACT_ADD_RESET = 'OBJET_CONTACT_ADD_RESET';

export const OBJET_CONTACT_DELETE_REQUEST = 'OBJET_CONTACT_DELETE_REQUEST';
export const OBJET_CONTACT_DELETE_SUCCESS = 'OBJET_CONTACT_DELETE_SUCCESS';
export const OBJET_CONTACT_DELETE_FAIL = 'OBJET_CONTACT_DELETE_FAIL';
export const OBJET_CONTACT_DELETE_RESET = 'OBJET_CONTACT_DELETE_RESET';

export const OBJET_CONTACT_UPDATE_REQUEST = 'OBJET_CONTACT_UPDATE_REQUEST';
export const OBJET_CONTACT_UPDATE_SUCCESS = 'OBJET_CONTACT_UPDATE_SUCCESS';
export const OBJET_CONTACT_UPDATE_FAIL = 'OBJET_CONTACT_UPDATE_FAIL';
export const OBJET_CONTACT_UPDATE_RESET = 'OBJET_CONTACT_UPDATE_RESET';
