export const OFFRE_EMPLOI_LIST_REQUEST = 'OFFRE_EMPLOI_LIST_REQUEST';
export const OFFRE_EMPLOI_LIST_SUCCESS = 'OFFRE_EMPLOI_LIST_SUCCESS';
export const OFFRE_EMPLOI_LIST_FAIL = 'OFFRE_EMPLOI_LIST_FAIL';

export const OFFRE_EMPLOI_ADD_REQUEST = 'OFFRE_EMPLOI_ADD_REQUEST';
export const OFFRE_EMPLOI_ADD_SUCCESS = 'OFFRE_EMPLOI_ADD_SUCCESS';
export const OFFRE_EMPLOI_ADD_FAIL = 'OFFRE_EMPLOI_ADD_FAIL';
export const OFFRE_EMPLOI_ADD_RESET = 'OFFRE_EMPLOI_ADD_RESET';

export const OFFRE_EMPLOI_DELETE_REQUEST = 'OFFRE_EMPLOI_DELETE_REQUEST';
export const OFFRE_EMPLOI_DELETE_SUCCESS = 'OFFRE_EMPLOI_DELETE_SUCCESS';
export const OFFRE_EMPLOI_DELETE_FAIL = 'OFFRE_EMPLOI_DELETE_FAIL';
export const OFFRE_EMPLOI_DELETE_RESET = 'OFFRE_EMPLOI_DELETE_RESET';

export const OFFRE_EMPLOI_UPDATE_REQUEST = 'OFFRE_EMPLOI_UPDATE_REQUEST';
export const OFFRE_EMPLOI_UPDATE_SUCCESS = 'OFFRE_EMPLOI_UPDATE_SUCCESS';
export const OFFRE_EMPLOI_UPDATE_FAIL = 'OFFRE_EMPLOI_UPDATE_FAIL';
export const OFFRE_EMPLOI_UPDATE_RESET = 'OFFRE_EMPLOI_UPDATE_RESET';
