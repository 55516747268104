import {
    INFORMATION_LIST_REQUEST,
    INFORMATION_LIST_SUCCESS,
    INFORMATION_LIST_FAIL,

    INFORMATION_ADD_REQUEST,
    INFORMATION_ADD_SUCCESS,
    INFORMATION_ADD_FAIL,
    INFORMATION_ADD_RESET,

    INFORMATION_DELETE_REQUEST,
    INFORMATION_DELETE_SUCCESS,
    INFORMATION_DELETE_FAIL,
    INFORMATION_DELETE_RESET,

    INFORMATION_UPDATE_REQUEST,
    INFORMATION_UPDATE_SUCCESS,
    INFORMATION_UPDATE_FAIL,
    INFORMATION_UPDATE_RESET
} from '../constants/informationConstants';

// Compare this snippet from user-interface/src/redux/reducers/informationReducers.js:
export const informationListReducer = (state = { informations: [] }, action) => {
    switch (action.type) {
        case INFORMATION_LIST_REQUEST:
            return { loading: true, informations: [] };
        case INFORMATION_LIST_SUCCESS:
            return { loading: false, informations: action.payload };
        case INFORMATION_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/informationReducers.js:
export const informationAddReducer = (state = {}, action) => {
    switch (action.type) {
        case INFORMATION_ADD_REQUEST:
            return { loading: true };
        case INFORMATION_ADD_SUCCESS:
            return { loading: false, success: true, information: action.payload };
        case INFORMATION_ADD_FAIL:
            return { loading: false, error: action.payload };
        case INFORMATION_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/informationReducers.js:
export const informationDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case INFORMATION_DELETE_REQUEST:
            return { loading: true };
        case INFORMATION_DELETE_SUCCESS:
            return { loading: false, success: true };
        case INFORMATION_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case INFORMATION_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/informationReducers.js:
export const informationUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case INFORMATION_UPDATE_REQUEST:
            return { loading: true };
        case INFORMATION_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case INFORMATION_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case INFORMATION_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
