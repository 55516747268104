import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import Modale from "../../components/Modale/Modale";
import { Link, useLocation } from "react-router-dom";
import {
  candidatureEmploiAddAction,
  candidatureEmploiAddResetAction,
} from "../../redux/actions/candidatureEmploiActions";
import "./style.css";
import { ActionButton } from "../../components/Button2/ActionButton/ActionButton";

export const CandidatureForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const fileInputRef = useRef(null);

  const { title, position, id } = location.state || {};
  const [typePoste, setTypePoste] = useState("");
  const [modaleState, setModaleState] = useState({
    isOpen: false,
    type: "",
    message: "",
  });
  const candidatureEmploiAdd = useSelector(
    (state) => state.candidatureEmploiAdd
  );
  const { loading, error, success } = candidatureEmploiAdd;

  const [formData, setFormData] = useState({
    type_poste: typePoste !== "" ? typePoste : position,
    job_offer_id: id ? id : "",
    civilite: "",
    nom: "",
    prenom: "",
    email: "",
    telephone: "",
    texte: "",
    documents: null,
  });

  // State to manage form errors
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (success) {
      setModaleState({
        isOpen: true,
        type: "add-success",
        message:
          "Votre candidature a été soumise avec succès! Nous vous contacterons sous peu.",
      });
    } else if (error) {
      setModaleState({
        isOpen: true,
        type: "add-error",
        message:
          "Une erreur s'est produite lors de la soumission de votre candidature. Veuillez réessayer.",
      });
    }
    dispatch(candidatureEmploiAddResetAction());
  }, [success, error, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFormData((prevData) => ({
      ...prevData,
      documents: [...(prevData.documents || []), ...selectedFiles],
    }));
  };

  const validateForm = () => {
    let errors = {};

    if (!formData.type_poste) {
      errors.type_poste = "Le type de poste est requis";
    }
    if (!formData.prenom) {
      errors.prenom = "Le prénom est requis";
    }
    if (!formData.nom) {
      errors.nom = "Le nom est requis";
    }
    if (!formData.email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!formData.email) {
        errors.email = "L'email est requis";
      } else if (!emailRegex.test(formData.email)) {
        errors.email = "L'email est invalide";
      }
    }
    if (!formData.telephone) {
      errors.telephone = "Le numéro de téléphone est requis";
    } else if (!/^\d{10}$/.test(formData.telephone)) {
      errors.telephone = "Le numéro de téléphone doit contenir 10 chiffres";
    }
    if (!formData.texte) {
      errors.texte = "Le message est requis";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Form is valid if no errors
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(candidatureEmploiAddAction(formData));

      setFormData({
        type_poste: position ? position : "",
        civilite: "",
        nom: "",
        prenom: "",
        email: "",
        telephone: "",
        texte: "",
        documents: null,
      });
    }
  };

  const handleCloseModale = () => {
    setModaleState({ ...modaleState, isOpen: false });
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="fiche-nous-rejoindre">
      <Helmet>
        <title>
          Rejoignez COODE-NT pour travailler sur des projets passionnants
        </title>
        <meta name="description" content="Candidature" />
      </Helmet>

      <div className="content-section">
        <div className="auto-layout-vertical">
          <p className="accueil-d-veloppeur">
            <span className="span">
              <Link className="no-decoration span" to="/accueil">
                Accueil
              </Link>
              &nbsp;&gt;&nbsp;
              <Link className="no-decoration span" to="/join">
                {title ? title : "Nous rejoindre"}
              </Link>{" "}
              &gt;
            </span>
            <span className="text-wrapper-5"> Formulaire</span>
          </p>
          <div className="frame-2">
            <div className="text-wrapper-6">Job</div>
            <div className="text-wrapper-7">
              {title ? title : "Nous rejoindre"}
            </div>
          </div>
          <div className="separation-line margin-zero" />
        </div>

        <div className="frame-3">
          <form onSubmit={handleSubmit}>
            {!id && (
              <div className="frame-4">
                <div className="text-wrapper-9">Poste</div>
                <input
                  type="text"
                  name="type_poste"
                  value={formData.type_poste}
                  onChange={handleChange}
                  required
                  placeholder="développeur"
                />
                {formErrors.type_poste && (
                  <p className="error-message">{formErrors.type_poste}</p>
                )}
              </div>
            )}
            <div className="frame-4">
              <div className="text-wrapper-9">Prénom</div>
              <input
                type="text"
                name="prenom"
                value={formData.prenom}
                onChange={handleChange}
                required
                placeholder="Jean-Luc"
              />
              {formErrors.prenom && (
                <p className="error-message">{formErrors.prenom}</p>
              )}
            </div>
            <div className="frame-4">
              <div className="text-wrapper-9">Nom</div>
              <input
                type="text"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                required
                placeholder="Nom"
              />
              {formErrors.nom && (
                <p className="error-message">{formErrors.nom}</p>
              )}
            </div>
            <div className="frame-4">
              <div className="text-wrapper-9">E-mail</div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="exemple@gmail.com"
              />
              {formErrors.email && (
                <p className="error-message">{formErrors.email}</p>
              )}
            </div>
            <div className="frame-4">
              <div className="text-wrapper-9">Numéro de téléphone</div>
              <input
                type="tel"
                name="telephone"
                value={formData.telephone}
                onChange={handleChange}
                required
                placeholder="07 00 00 00 00"
              />
              {formErrors.telephone && (
                <p className="error-message">{formErrors.telephone}</p>
              )}
            </div>
            <div className="frame-4">
              <div className="text-wrapper-9">Parlez-nous de vous</div>
              <textarea
                name="texte"
                value={formData.texte}
                onChange={handleChange}
                required
                placeholder="Message"
                rows="4"
              />
              {formErrors.texte && (
                <p className="error-message">{formErrors.texte}</p>
              )}
            </div>

            <div className="frame-4">
              <span className="text-wrapper-9">
                Partagez-nous votre CV et vos réalisations (max 2GO)
              </span>
              <div className="field-4">
                <div className="file-placeholder">
                  {formData.documents && formData.documents.length > 0
                    ? formData.documents.map((file) => file.name).join(", ") // Affiche la liste des fichiers
                    : "Aucun fichier sélectionné"}
                </div>

                <div className="spacer" />
              </div>

              <input
                type="file"
                accept=".pdf,.doc,.docx"
                name="documents"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="upload-file"
                multiple
              />
              <label htmlFor="upload-file">
                <span onClick={triggerFileInput}>
                  <ActionButton style="outlined" text="Joindre un autre fichier"
                  />
                </span>
              </label>
            </div>
            <span className="submit-button">
              <ActionButton style="filled" text="Envoyer" type="submit" />
            </span>
          </form>
        </div>
      </div>
      <Modale
        isOpen={modaleState.isOpen}
        onClose={handleCloseModale}
        title={modaleState.type === "add-success" ? "Succès" : "Erreur"}
        type={modaleState.type}
      >
        <p>{modaleState.message}</p>
      </Modale>
    </div>
  );
};
