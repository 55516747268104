// Constantes pour récupérer la liste des membres de l'équipe
export const TEAM_MEMBER_LIST_REQUEST = 'TEAM_MEMBER_LIST_REQUEST';
export const TEAM_MEMBER_LIST_SUCCESS = 'TEAM_MEMBER_LIST_SUCCESS';
export const TEAM_MEMBER_LIST_FAIL = 'TEAM_MEMBER_LIST_FAIL';

// Constantes pour ajouter un membre de l'équipe
export const TEAM_MEMBER_ADD_REQUEST = 'TEAM_MEMBER_ADD_REQUEST';
export const TEAM_MEMBER_ADD_SUCCESS = 'TEAM_MEMBER_ADD_SUCCESS';
export const TEAM_MEMBER_ADD_FAIL = 'TEAM_MEMBER_ADD_FAIL';
export const TEAM_MEMBER_ADD_RESET = 'TEAM_MEMBER_ADD_RESET';

// Constantes pour supprimer un membre de l'équipe
export const TEAM_MEMBER_DELETE_REQUEST = 'TEAM_MEMBER_DELETE_REQUEST';
export const TEAM_MEMBER_DELETE_SUCCESS = 'TEAM_MEMBER_DELETE_SUCCESS';
export const TEAM_MEMBER_DELETE_FAIL = 'TEAM_MEMBER_DELETE_FAIL';
export const TEAM_MEMBER_DELETE_RESET = 'TEAM_MEMBER_DELETE_RESET';

// Constantes pour mettre à jour un membre de l'équipe
export const TEAM_MEMBER_UPDATE_REQUEST = 'TEAM_MEMBER_UPDATE_REQUEST';
export const TEAM_MEMBER_UPDATE_SUCCESS = 'TEAM_MEMBER_UPDATE_SUCCESS';
export const TEAM_MEMBER_UPDATE_FAIL = 'TEAM_MEMBER_UPDATE_FAIL';
export const TEAM_MEMBER_UPDATE_RESET = 'TEAM_MEMBER_UPDATE_RESET';

// Constantes pour mettre à jour l'ordre des membres de l'équipe
export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';