import PropTypes from "prop-types";
import React, { useState } from "react";
import {Link} from "react-router-dom";

import "./LinkButton.css";

export const LinkButton = ({ style, className, text = "Do something", divClassName, to , onClick}) => {
    // États pour savoir si le bouton est survolé ou cliqué
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    // Fonctions pour gérer l'entrée et la sortie du survol
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setIsClicked(false); // Réinitialiser l'état cliqué si la souris quitte le bouton
    };

    // Fonctions pour gérer le clic
    const handleMouseDown = () => {
        setIsClicked(true);
    };

    const handleMouseUp = () => {
        setIsClicked(false);
    };

    // Détermine quelle classe utiliser (remplace filled par outlined et vice versa au survol)
    const buttonStyleClass = isHovered
        ? style === "filled" ? "style-0-outlined" : "style-0-filled"
        : `style-0-${style}`;

    // Classe dynamique ajoutée si le bouton est cliqué
    const clickClass = isClicked ? "clicked" : "";

    // Classes combinées pour le bouton
    const linkClassName = `style-outlined-wrapper ${buttonStyleClass} ${clickClass} ${className}`;
    const divClassNameFinal = `do-something-2 ${divClassName}`;

    // Vérifie si l'URL est externe ou interne
    const isExternalLink = to.startsWith('http');

    if (isExternalLink) {
        return (
            <a
                href={to}
                target="_blank"
                rel="noopener noreferrer"
                className={linkClassName}
                onClick={onClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
            >
                <div className={divClassNameFinal}>{text}</div>
            </a>
        );
    } else {
        return (
            <Link
                to={to}
                className={linkClassName}
                onClick={onClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
            >
                <div className={divClassNameFinal}>{text}</div>
            </Link>
        );
    }
};

LinkButton.propTypes = {
    style: PropTypes.oneOf(["filled", "outlined"]),
    text: PropTypes.string,
    to: PropTypes.string.isRequired, // URL de destination requise
    onClick: PropTypes.func,
};

LinkButton.defaultProps = {
    to: "/",
};