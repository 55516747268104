// ProjetSection.js
import React, { useEffect } from 'react';
import './ProjetSection.css';
import { LinkButton } from "../Button2/LinkButton/LinkButton";
import { projetListAction } from '../../redux/actions/projetActions';
import { openModal, closeModal, addAvertissement } from '../../redux/Slices/etreAvertiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PlaceholderImage3 } from "../../icons/PlaceholderImage3";
import EtreAverti from '../Modale/EtreAverti/EtreAverti';
import { ToastContainer, toast } from 'react-toastify'; // Import de react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import du CSS

const ProjetSection = () => {
    const dispatch = useDispatch();

    // Récupérer l'état du slice projetList
    const projetList = useSelector((state) => state.projetList);
    const { loading, error, projets } = projetList;

    // Récupérer l'état du slice etreAverti
    const { isOpen, error: errorAverti, successMessage, loading: loadingAverti } = useSelector((state) => state.etreAverti);

    useEffect(() => {
        dispatch(projetListAction());
    }, [dispatch]);

    // Afficher des notifications de succès ou d'erreur
    useEffect(() => {
        if (successMessage) {
            toast.success(successMessage);
        }
        if (errorAverti) {
            toast.error(errorAverti);
        }
    }, [successMessage, errorAverti]);

    const handleModalSubmit = (data) => {
        dispatch(addAvertissement(data));
    };

    return (
        <div className="projets-container">
            <div className="content-section-2">
                {loading ? (
                    <p>Chargement des projets...</p>
                ) : error ? (
                    <p>Aucun projet trouvé {console.log('Erreur lors de la récupération des projets : ', error)}</p>
                ) : (
                    projets && projets.length > 0 ? (
                        projets.map((projet) => (
                            <div key={projet.id} className="auto-layout-2">
                                <div className="auto-layout-vertical">
                                    <div className="auto-layout-vertical-2">
                                        <h4 className="text-wrapper-6">
                                            {projet.titre || 'Titre indisponible'}
                                        </h4>
                                        <p className="p">
                                            {projet.slogan && (
                                                <>
                                                    {projet.slogan}
                                                </>
                                            )}
                                            {projet.description && (
                                                <>
                                                    <br />
                                                    <br />
                                                    <div dangerouslySetInnerHTML={{ __html: projet.description }} />
                                                </>
                                            )}
                                        </p>
                                    </div>
                                    <LinkButton
                                        className="design-component-instance-node-2"
                                        style="outlined"
                                        text={projet.urlSiteWeb ? "Découvrir" : "Être averti"}
                                        to={projet.urlSiteWeb || "#"}
                                        onClick={(e) => {
                                            if (!projet.urlSiteWeb) {
                                                e.preventDefault();
                                                dispatch(openModal());
                                            }
                                        }}
                                    />
                                </div>

                                {projet.image ? <img
                                    src={projet.image}
                                    alt="Projet"
                                    className="imported-image"
                                /> :
                                    <PlaceholderImage3 className="placeholder-image" />
                                }
                            </div>
                        ))
                    ) : (
                        <p>Aucun projet trouvé</p>
                    )
                )}
            </div>
            <EtreAverti
                isOpen={isOpen}
                onClose={() => dispatch(closeModal())}
                onSubmit={handleModalSubmit}
                loading={loadingAverti} // Ajout de l'indicateur de chargement
            />
            <ToastContainer autoClose={2000} />
        </div>
    );
};

export default ProjetSection;
