import PropTypes from "prop-types";
import React from "react";
import "./StyleOutlinedWrapper.css";

export const StyleOutlinedWrapper = ({ style, className, divClassName, text = "Do something" }) => {
  return (
    <button className={`style-outlined-wrapper style-0-${style} ${className}`}>
      <div className={`do-something-2 ${divClassName}`}>{text}</div>
    </button>
  );
};

StyleOutlinedWrapper.propTypes = {
  style: PropTypes.oneOf(["filled", "outlined"]),
  text: PropTypes.string,
};

