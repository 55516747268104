export const isValidEmail = (email) => {
    // Regex pour valider l'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

// Fonction pour vérifier si l'email est jetable (elle ne dépend plus de useSelector)
export const isDisposableEmail = (email, disposableDomains) => {
    const domain = email.split('@')[1];
    return disposableDomains.includes(domain);
};
