// store.js
import { configureStore } from '@reduxjs/toolkit'
import {thunk} from 'redux-thunk';
import { rootReducer } from './reducers/appReducer';


// const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    // Pas besoin d'utiliser composeWithDevTools, Redux Toolkit le gère automatiquement en mode développement
  });

export default store;
